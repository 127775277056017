import React, { useState, useEffect } from "react";

import repository from './repository';
import Quality    from '@/componentes/quality';
import { Link }   from 'react-router-dom'

import Template from '@/modules/admin/template';
import ComboStatus from '@/modules/admin/combos/status';

export function Client() {

  const dataInitial = {
    status: true
  };

  const [list, setList] = useState([]);
    
  useEffect(() => {
    handleList();
  }, []);

  const handleList = (e) => {

    new Promise((resolve, reject) => {
      
      if(e !== undefined){

        resolve({...dataInitial, [e.target.getAttribute('name')]:  e.target.value});
      }
      else{
        resolve(dataInitial);
      }
      
    }).then((filters) => {

      repository.getList(filters, (response) => {
        setList(response.data);
      });
    });
  }

  return (
    <>
      <Template title="Clientes">
        <div className="table_grid_filter">
          <div className="row">
            <div className="col-sm-3">Status</div>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <ComboStatus handleList={handleList} />
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table_grid">
            <thead>
              <tr>
                <th>Titulo</th>
                <th>CNPJ</th>
                <th>Status</th>
                <th className="option">
                  <Link to="/admin/clients/form">
                    <button>Novo</button>
                  </Link>
                </th>
              </tr>
            </thead>
            <tbody>
              {list &&
              list.map((obj, index) => (
                <tr key={index}>
                  <td>{obj.title}</td>
                  <td>{obj.cnpj}</td>
                  <td><Quality value={obj.status} /></td>
                  <td className="option">
                    <Link to={`/admin/clients/form/${obj.id}`}>
                      <button>Editar</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Template>
    </>
  )
}

export default Client;