export default function(type, user) {

    return {
      type: type,
      payload:{
        username: user.name,
        client: user.clientTitle,
        cliType: user.clientType
      }
    };
  }