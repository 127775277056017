import customApi from "@/componentes/api";

const URLS_SAVE = {
  DEV: "https://run.mocky.io/v3/bd94ff8e-7d58-4020-945e-654c6329edb2",
  HOMOLOG: "http://localhost:8080/api/admin/line/lots",
  PROD: "/api/admin/line/lots"
}

const URLS_GET = {
  DEV: "https://run.mocky.io/v3/f7a565fe-74e7-49db-b0e1-685caf993a5c",
  HOMOLOG: "http://localhost:8080/api/admin/line/lots/:id",
  PROD: "/api/admin/line/lots/:id"
}

const URLS_LIST = {
  DEV: "https://run.mocky.io/v3/d54e9496-cc8c-4caa-bb92-74a04a76bca1",
  HOMOLOG: "http://localhost:8080/api/admin/line/lots",
  PROD: "/api/admin/line/lots"
}

const URLS_COMBO = {
  DEV: "https://run.mocky.io/v3/14151716-30c1-46d5-85c0-d8a147f34fde",
  HOMOLOG: "http://localhost:8080/api/admin/line/lots/combo",
  PROD: "/api/admin/line/lots/combo"
}

const URLS_COMBO_OPER = {
  DEV: "https://run.mocky.io/v3/14151716-30c1-46d5-85c0-d8a147f34fde",
  HOMOLOG: "http://localhost:8080/api/admin/line/lots/combo/operators",
  PROD: "/api/admin/line/lots/combo/operators"
}

function getList(params, callback) {

  customApi.get(URLS_LIST, params, callback);
}

function getView(id, callback) {

  var params = {":id": id};

  customApi.getPath(URLS_GET, params, callback);
}

function save(obj, callback) {

  customApi.post(URLS_SAVE, obj, callback);
}

function getCombo(callback) {

  customApi.get(URLS_COMBO, {}, callback);
}

function getComboOperators(callback) {

  customApi.get(URLS_COMBO_OPER, {}, callback);
}

export default {
  getList, getView, save, getCombo, getComboOperators
};