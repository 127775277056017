export default function(type, clientId, list) {

  var result = {
    type: type,
    payload:{
      clientId: clientId,
      list: list
    }
  };
 
  return result
}