import React, { useState, useEffect } from "react";

import repository from './repository';
import Quality    from '@/componentes/quality';
import { Link }   from 'react-router-dom'

import Template from '@/modules/admin/template';
import ComboStatus  from '@/modules/admin/combos/status';

export function Vehicle() {

  const dataInitial = {
    status: true
  };

  const [list, setList]       = useState([]);
  const [filters, setFilters] = useState(dataInitial);
      
  useEffect(() => {
    handleList();
  }, []);

  const handleList = (e) => {

    new Promise((resolve, reject) => {
      
      if(e !== undefined){

        var params = {...filters, 
                     [e.target.getAttribute('name')]:  e.target.value};

        setFilters(params);

        resolve(params);
      }
      else{
        resolve({...filters});
      }
      
    }).then((result) => {

      repository.getList(result, (response) => {
        setList(response.data);
      });
    });
  }

  return (
    <>
      <Template title="Veículos">
        <div className="table-responsive">
          <div className="table_grid_filter">
            <div className="row">
              <div className="col-sm-3">Status</div>
            </div>
            <div className="row">
              <div className="col-sm-3">
                <ComboStatus handleList={handleList} />
              </div>
            </div>
          </div>
          <table className="table table-striped table_grid">
            <thead>
              <tr>
                <th>Cliente</th>
                <th>Veiculo</th>
                <th>Placa</th>
                <th>Ano Modelo</th>
                <th>Ano Fabricação</th>
                <th>Status</th>
                <th className="option">
                  <Link to="/admin/vehicles/form">
                    <button>Novo</button>
                  </Link>
                </th>
              </tr>
            </thead>
            <tbody>
              {list &&
              list.map((obj, index) => (
                <tr key={index}>
                  <td>{obj.clientTitle}</td>
                  <td>{obj.title}</td>
                  <td>{obj.plate}</td>
                  <td>{obj.yearMod}</td>
                  <td>{obj.yearFactory}</td>
                  <td><Quality value={obj.status} /></td>
                  <td className="option">
                    <Link to={`/admin/vehicles/form/${obj.id}`}>
                      <button>Editar</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Template>
    </>
  )
}

export default Vehicle;