
  export default function parseResult(data){

    var clients = data.map(client => {

      var vehicles = client.children.map(vehicle => {

        var groups = [];

        var type;

        if(vehicle["children"]){

          groups = vehicle.children.map(group => {

            var groupNew = {
              label: group.name,
              key: group.idVeiculo,
              type: "VEHICLE"
            };

            return groupNew
          });

          type = "GROUP";
        }
        else{
          type = "VEHICLE";
        }

        var newVehicle = {
          key: vehicle.idVeiculo,
          label: vehicle.name,
          nodes: groups,
          type: type
        };

        return newVehicle;
      });
  
      return {
        label: client.name.toUpperCase(),
        key: client.idCliente,
        type: "CLIENT",
        nodes: vehicles,
      };

    });

    return clients;
  }