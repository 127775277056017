import styled from 'styled-components';

export const GridRastreamento = styled.div`
    table{
        border-right: 1px solid #dee2e6;
    }

    th {        
        border-right: 1px solid #dee2e6;
        vertical-align: bottom;
    }

    .grid-empty{
        padding: 5px;
        background-color: #fff;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        border: 1px solid #dee2e6;
    }
`