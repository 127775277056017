import React, {useEffect} from "react";

import { Link } from 'react-router-dom'
import alertify from 'alertifyjs';

import repository         from './repository';
import { Form, IconList } from '@/componentes/form/styles';
import Template           from '@/modules/admin/template';
import useForm            from '@/componentes/form/useForm';

import ComboMod from '@/modules/admin/combos/mod';

export function DeviceLotForm(props) {

    const url = "/admin/device/lots";

    const dataInitial = {
        title: "",
        contract: "",
        modId: 0,
        id: 0
    };

    const {values, setValues, handleChange, clearForm} = useForm(dataInitial);
    
    useEffect(() => {

        if (props.match.params.id) {
            
            repository.getView(props.match.params.id, (response) => {

                setValues(response.data);                
            });
        }
   
    }, [props.match.params.id, setValues]);

    const handleSubmit = (e) => {

        e.preventDefault();

        repository.save(values, (response) => {

            alertify.success(response.data.message);
    
            clearForm();
    
            props.history.push(url);
        });
    }

    return (
        <>
            <Template title="Lote de Equipamentos">

                <Form onSubmit={handleSubmit}>
                    <main className="container">
                        
                        <div className="row">
                            <div className="col-sm-12">
                                <Link to={url}>
                                    <IconList size={30} />
                                </Link>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6">
                                <label htmlFor="group">Modelo</label>
                                <ComboMod handle={handleChange} name="modId" value={values.modId} />
                            </div>
                            <div className="col-sm-6">
                                <label>Titulo</label>
                                <input type="text" 
                                        name="title" 
                                        id="title" 
                                        maxLength="50" 
                                        value={values.title}
                                        onChange={handleChange} />
                            </div>
                        </div>
                       
                        <div className="row">
                            <div className="col-sm-6">
                                <label htmlFor="login">Contrato</label>
                                <input type="text" 
                                        name="contract" 
                                        id="contract" 
                                        maxLength="30" 
                                        value={values.contract}
                                        onChange={handleChange} />
                            </div>
                        </div>
                        <div className="row">
                            <button>Salvar</button>
                        </div>
                    </main>
                </Form>
            </Template>
        </>
    )
}

export default DeviceLotForm;