import React from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import ReactDom from 'react-dom'

import { Provider } from 'react-redux'
import store from '@/store';

import 'alertifyjs/build/css/alertify.css';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/fonts/fonts.css';
import './index.css';
import './mobione.css';

import 'rc-tooltip/assets/bootstrap_white.css';

import alertify from 'alertifyjs';

import Login from "@/modules/login/Login";
import Operacao from "@/modules/operacao";
import Admin from "@/modules/admin";
import Client from "@/modules/admin/pages/client";
import ClientForm from "@/modules/admin/pages/client/form";
import Group from "@/modules/admin/pages/group";
import GroupForm from "@/modules/admin/pages/group/form";
import User from "@/modules/admin/pages/user";
import UserForm from "@/modules/admin/pages/user/form";
import Vehicle from "@/modules/admin/pages/vehicle";
import VehicleForm from "@/modules/admin/pages/vehicle/form";
import Profile from "@/modules/admin/pages/profile";
import ProfileForm from "@/modules/admin/pages/profile/form";
import Device from "@/modules/admin/pages/device";
import DeviceForm from "@/modules/admin/pages/device/form";
import DeviceLot from "@/modules/admin/pages/device_lot";
import DeviceLotForm from "@/modules/admin/pages/device_lot/form";
import LineLot from "@/modules/admin/pages/line_lot";
import LineLotForm from "@/modules/admin/pages/line_lot/form";
import Line from "@/modules/admin/pages/line";
import LineForm from "@/modules/admin/pages/line/form";
import DeviceActive from "@/modules/admin/pages/active";
import DeviceActiveForm from "@/modules/admin/pages/active/form";

import ReportPosition from "@/modules/report/pages/position";
import ReportControl from "@/modules/report/pages/control";

const el = document.getElementById('root');

alertify.defaults = {
    // dialogs defaults
    autoReset: true,
    basic: false,
    closable: true,
    closableByDimmer: true,
    invokeOnCloseOff: false,
    frameless: false,
    defaultFocusOff: false,
    maintainFocus: true, // <== global default not per instance, applies to all dialogs
    maximizable: true,
    modal: true,
    movable: true,
    moveBounded: false,
    overflow: true,
    padding: true,
    pinnable: true,
    pinned: true,
    preventBodyShift: false, // <== global default not per instance, applies to all dialogs
    resizable: true,
    startMaximized: false,
    transition: 'pulse',
    transitionOff: false,
    tabbable: 'button:not(:disabled):not(.ajs-reset),[href]:not(:disabled):not(.ajs-reset),input:not(:disabled):not(.ajs-reset),select:not(:disabled):not(.ajs-reset),textarea:not(:disabled):not(.ajs-reset),[tabindex]:not([tabindex^="-"]):not(:disabled):not(.ajs-reset)',  // <== global default not per instance, applies to all dialogs

    // notifier defaults
    notifier: {
        // auto-dismiss wait time (in seconds)  
        delay: 4,
        // default position
        position: 'bottom-right',
        // adds a close button to notifier messages
        closeButton: true,
        // provides the ability to rename notifier classes
        classes: {
            base: 'alertify-notifier',
            prefix: 'ajs-',
            message: 'ajs-message',
            top: 'ajs-top',
            right: 'ajs-right',
            bottom: 'ajs-bottom',
            left: 'ajs-left',
            center: 'ajs-center',
            visible: 'ajs-visible',
            hidden: 'ajs-hidden',
            close: 'ajs-close'
        }
    },

    // language resources 
    glossary: {
        // dialogs default title
        title: 'Plataforma Mobione',
        // ok button text
        ok: 'OK',
        // cancel button text
        cancel: 'Cancelar'
    },

    // theme settings
    theme: {
        // class name attached to prompt dialog input textbox.
        input: 'ajs-input',
        // class name attached to ok button
        ok: 'ajs-ok',
        // class name attached to cancel button 
        cancel: 'ajs-cancel'
    },
    // global hooks
    hooks: {
        // invoked before initializing any dialog
        preinit: function (instance) { },
        // invoked after initializing any dialog
        postinit: function (instance) { },
    },
};

ReactDom.render(

    <Provider store={store}>
        <BrowserRouter basename={`${process.env.REACT_APP_BASEDIR}`}>
            <Route exact path="/signin" component={Login} />
            <Route exact path="/app" component={Operacao} />
            <Route exact path="/admin" component={Admin} />
            <Route exact path="/admin/clients" component={Client} />
            <Route exact path="/admin/clients/form" component={ClientForm} />
            <Route exact path="/admin/clients/form/:id" component={ClientForm} />
            <Route exact path="/admin/groups" component={Group} />
            <Route exact path="/admin/groups/form" component={GroupForm} />
            <Route exact path="/admin/groups/form/:id" component={GroupForm} />
            <Route exact path="/admin/users" component={User} />
            <Route exact path="/admin/users/form" component={UserForm} />
            <Route exact path="/admin/users/form/:id" component={UserForm} />
            <Route exact path="/admin/vehicles" component={Vehicle} />
            <Route exact path="/admin/vehicles/form" component={VehicleForm} />
            <Route exact path="/admin/vehicles/form/:id" component={VehicleForm} />
            <Route exact path="/admin/profiles" component={Profile} />
            <Route exact path="/admin/profiles/form" component={ProfileForm} />
            <Route exact path="/admin/profiles/form/:id" component={ProfileForm} />
            <Route exact path="/admin/line/lots" component={LineLot} />
            <Route exact path="/admin/line/lots/form" component={LineLotForm} />
            <Route exact path="/admin/line/lots/form/:id" component={LineLotForm} />
            <Route exact path="/admin/lines" component={Line} />
            <Route exact path="/admin/lines/form" component={LineForm} />
            <Route exact path="/admin/lines/form/:id" component={LineForm} />
            <Route exact path="/admin/devices" component={Device} />
            <Route exact path="/admin/devices/form" component={DeviceForm} />
            <Route exact path="/admin/devices/form/:id" component={DeviceForm} />
            <Route exact path="/admin/device/lots" component={DeviceLot} />
            <Route exact path="/admin/device/lots/form" component={DeviceLotForm} />
            <Route exact path="/admin/device/lots/form/:id" component={DeviceLotForm} />
            <Route exact path="/admin/device/actives" component={DeviceActive} />
            <Route exact path="/admin/device/actives/form" component={DeviceActiveForm} />
            <Route exact path="/admin/device/actives/form/:id" component={DeviceActiveForm} />
            <Route exact path="/report/position" component={ReportPosition} />
            <Route exact path="/report/control" component={ReportControl} />
            <Route exact path="/">
                <Redirect to="/signin" />
            </Route>
            <Route exact path="">
                <Redirect to="/signin" />
            </Route>
        </BrowserRouter>
    </Provider>

    , el)