import PropTypes from "prop-types";
import React from "react";

import { FaVolumeUp, FaVolumeMute } from "react-icons/fa";

import Tooltip from 'rc-tooltip';

export class ButtonVol extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        const { actual, toggleSelected } = this.props;

        var icon;
        var msgTooltip;

        if(actual > 0){
           icon = <FaVolumeUp size="25" />
           msgTooltip = "Desativar o Alerta Sonoro"
        }
        else{
            icon = <FaVolumeMute size="25" />
            msgTooltip = "Ativar o Alerta Sonoro"
        }

        return (
            <Tooltip placement="right" overlay={msgTooltip}>
                <div className="toggle-container link" onClick={toggleSelected}>
                    {icon}
                </div>
            </Tooltip>
        );
    }
}

ButtonVol.propTypes = {
    actual: PropTypes.bool.isRequired,
    toggleSelected: PropTypes.func.isRequired
};