import React, {useEffect} from "react";

import { Link } from 'react-router-dom'
import alertify from 'alertifyjs';

import repository         from './repository';
import { Form, IconList } from '@/componentes/form/styles';
import Template           from '@/modules/admin/template';
import useForm            from '@/componentes/form/useForm';

import ComboLineLot from '@/modules/admin/combos/line_lot';

export function LineForm(props) {

    const url = "/admin/lines";

    const dataInitial = {
        number: "",
        simcard: "",
        lineLotId: 0,
        status: true,
        id: 0
    };

    const {values, setValues, handleChange, clearForm} = useForm(dataInitial);
    
    useEffect(() => {

        if (props.match.params.id) {
            
            repository.getView(props.match.params.id, (response) => {

                setValues(response.data);                
            });
        }
   
    }, [props.match.params.id, setValues]);

    const handleSubmit = (e) => {

        e.preventDefault();

        repository.save(values, (response) => {

            alertify.success(response.data.message);
    
            clearForm();
    
            props.history.push(url);
        });
    }

    return (
        <>
            <Template title="Linhas">

                <Form onSubmit={handleSubmit}>
                    <main className="container">
                        
                        <div className="row">
                            <div className="col-sm-12">
                                <Link to={url}>
                                    <IconList size={30} />
                                </Link>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6">
                                <label htmlFor="group">Lote</label>
                                <ComboLineLot handle={handleChange} name="lineLotId" value={values.lineLotId} />
                            </div>
                            <div className="col-sm-6">
                                <label>Numero</label>
                                <input type="text" 
                                        name="number" 
                                        id="number" 
                                        maxLength="20" 
                                        value={values.number}
                                        onChange={handleChange} />
                            </div>
                        </div>
                       
                        <div className="row">
                            <div className="col-sm-6">
                                <label htmlFor="status">Status</label>
                                <select value={values.status} onChange={handleChange} name="status" id="status" >
                                    <option value={true}>Ativo</option>
                                    <option value={false}>Desativado</option>
                                </select>
                            </div>
                            <div className="col-sm-6">
                                <label htmlFor="login">Simcard</label>
                                <input type="text" 
                                        name="simcard" 
                                        id="simcard" 
                                        maxLength="30" 
                                        value={values.simcard}
                                        onChange={handleChange} />
                            </div>
                        </div>
                        <div className="row">
                            <button>Salvar</button>
                        </div>
                    </main>
                </Form>
            </Template>
        </>
    )
}

export default LineForm;