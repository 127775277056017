import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import alertify from 'alertifyjs';

import repository from './repository';
import clientRepository from '@/modules/admin/pages/client/repository';

export function ComboClient() {

  const history = useHistory();

  const [combos, setCombos] = useState([])
  const [clientSelected, setClientSelected] = useState(0)

  useEffect(() => {

    clientRepository.getCombo((response) => {

      setCombos(response.data.combo)
      setClientSelected(response.data.clientSelected)
    });

  }, []);

  const handle = (e) => {

    repository.getView(e.target.value, (response) => {

      alertify.success(response.data);

      history.push("/admin");
    });
  };

  const html = () => {

    var content = <option value="0"></option>

    if (combos.length > 0) {

      content = combos.map(item => {
        return <option key={item.id} value={item.id}>{item.title}</option>
      });
    }

    return content;
  }

  return (
    <>
      <select value={clientSelected} onChange={handle} name="client">
        {html()}
      </select>
    </>
  )
}

export default ComboClient;