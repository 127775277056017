import customApi from "@/componentes/api";

const URLS = {
  DEV: "https://run.mocky.io/v3/5db13816-ecbc-4f1f-8143-a19cdd37cc8a",
  HOMOLOG: "http://localhost:8080/api/operation/grid/:param",
  PROD: "/api/operation/grid/:param"
}

function getGrid(clientId, callback) {
 
  var params = {":param":clientId};

  customApi.getPath(URLS, params, callback);
}

export default {
  getGrid
};