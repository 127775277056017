import React from 'react';

import styled from 'styled-components'
import Header from '@/modules/base/header';
import Menu from './menu'
import Navigation from './navigation'

import ImgLoader from '@/assets/img/loader/loader.gif';

import {Loader} from '@/componentes/loader/styles';

const Wrapper = styled.div`
  width: 100%;
`;

const Body = styled.div`
  padding: 15px;
`;

export function Template(props) {

  return (
    <>
      <Wrapper>
          <Loader className="loader">
            <img src={ImgLoader} alt="" />
          </Loader>
          <Header />
          <Menu />
          <Navigation name={props.title} />
          <Body>
            {props.children}
          </Body>
      </Wrapper>
    </>
  )  
}

export default Template;