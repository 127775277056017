import React from 'react';

import { Navbar, Nav } from 'react-bootstrap'
import logo from  '@/assets/img/wisetrack_logo.png';

import Menu from './menu';

export function Header() {

  return (
    <div>
      <header className="mb-2 container-fluid">
        <Navbar className="header-nav">
            <Navbar.Brand as="a" href="#home" className="header-nav__logo mb-2">
              <img src={logo} alt="Wisetrack" />
            </Navbar.Brand>
            <Nav as="div">
              <Menu />
            </Nav>
        </Navbar>
      </header>
    </div>
  )  
}

export default Header;