import customApi from "@/componentes/api";

const URLS_PRINCIPAL = {
  DEV: "https://run.mocky.io/v3/678227fd-35a0-4cad-84f4-f27a4bf6f051",
  HOMOLOG: "http://localhost:8080/api/security/principal",
  PROD: "/api/security/principal"
}


function getPrincipal(callback) {

  customApi.get(URLS_PRINCIPAL, {}, callback);
}


export default {
  getPrincipal
};