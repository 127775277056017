import React, { useState, useEffect } from "react";
import Template from '@/modules/report/template';
import repository from './repository';
import "./index.css";

export function ReportControl() {

  const [list, setList] = useState([]);
  const [total, setTotal] = useState([]);
    
  const sendRequest = () => {

    var params = {}

    repository.getList(params, (response) => {
      setList(response.data.list);
      setTotal(response.data.total);

      console.log(list)
      console.log(total)
    });
  }

  useEffect(() => {
    sendRequest()
  }, []);

  return (
    <>
      <Template title="Relatório de Controle">
        <div className="table-responsive relatorio_controle">
          <table className="table table-striped table_grid total">
            <thead>
              <tr>
                {total &&
                  total.map((obj, index) => (
                      <th>{obj.msg}</th>
                  ))}
                </tr>
            </thead>
            <tbody>
            <tr>
              {total &&
                total.map((obj, index) => (
                    <th className={obj.classname}>{obj.total}</th>
                ))}
              </tr>
            </tbody>
          </table>

          <table className="table table-striped table_grid">
            <thead>
              <tr>
                <th>Veículo</th>
                <th>Equip.</th>
                <th>Linha</th>
                <th>Data Evento</th>
                <th>Data Sistema</th>
                <th>Latitude</th>
                <th>Longitude</th>
                <th>Localização</th>
              </tr>
            </thead>
            <tbody>
              {list &&
                list.map((obj, index) => (
                  <tr key={index} className={obj.classname}>
                    <td>{obj.vehicle}</td>
                    <td>{obj.userId}</td>
                    <td>{obj.line}</td>
                    <td>{obj.datetime}</td>
                    <td>{obj.received}</td>
                    <td>{obj.latitude}</td>
                    <td>{obj.longitude}</td>
                    <td>{obj.address}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Template>
    </>
  )
}

export default ReportControl;