import React,  { useState}  from 'react';

import {MenuComp, MenuResponsive} from './styles'

import { AiOutlineLink } from "react-icons/ai";

import { Link } from 'react-router-dom'

export function Menu() {

  const [responsive, setResponsive] = useState(0);
  
  const open = function(){

    if(responsive === 0){
      setResponsive(1)
    }
    else{
      setResponsive(0)
    }
  };

  return (

    <MenuComp>
      <div className={responsive === 1 ? 'responsive' : ''}>
        <Link to="/report/position"><AiOutlineLink /> Rel. de Posições</Link>
        <Link to="/report/control"><AiOutlineLink /> Rel. de Controle</Link>
        <a className="icon" onClick={open}>
          <MenuResponsive size={30} />
        </a>
      </div>
    </MenuComp>
  )  
}

export default Menu;