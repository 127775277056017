import styled from 'styled-components'

export const Loader = styled.div`
    position: absolute;
    top: 50%; 
    margin-top: -25px;
    left: 50%; 
    margin-left: -100px;
    color: white;
    text-align: center;
    width: 200px;
`;