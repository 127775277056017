import React, { useState, useEffect } from "react";

import repository from './repository';
import Template from '@/modules/admin/template';
import Quality from '@/componentes/quality';
import { Link } from 'react-router-dom'

import ComboStatus from '@/modules/admin/combos/status';

export function Line() {

  const dataInitial = {
    number: "",
    status: true
  };

  const [list, setList] = useState([]);
  const [filters, setFilters] = useState(dataInitial);

  useEffect(() => {
    handleList();
  }, []);

  const handleList = (e) => {

    new Promise((resolve, reject) => {

      if (e !== undefined) {

        var params = {
          ...filters,
          [e.target.getAttribute('name')]: e.target.value
        };

        setFilters(params);

        resolve(params);
      }
      else {
        resolve({ ...filters });
      }

    }).then((result) => {

      repository.getList(result, (response) => {
        setList(response.data);
      });
    });
  };

  return (
    <>
      <Template title="Linhas">
        <div class="table-responsive">
          <div className="table_grid_filter">
            <div class="row">
              <div className="col-sm-3">Status</div>
              <div className="col-sm-3">Número</div>
            </div>
            <div class="row">
              <div className="col-sm-3">
                <ComboStatus handleList={handleList} />
              </div>
              <div className="col-sm-3">
                <input type="text" onChange={handleList} name="number" id="number" maxLength="12" />
              </div>
            </div>
          </div>
          <table class="table table-striped table_grid">
            <thead>
              <tr>
                <th>Numero</th>
                <th>Simcard</th>
                <th>Status</th>
                <th>Lote</th>
                <th>Operadora</th>
                <th>Equipamento</th>
                <th>Veiculo</th>
                <th>Cliente</th>
                <th className="option">
                  <Link to="/admin/lines/form">
                    <button>Novo</button>
                  </Link>
                </th>
              </tr>
            </thead>
            <tbody>
              {list &&
                list.map((obj, index) => (
                  <tr key={index}>
                    <td>{obj.number}</td>
                    <td>{obj.simcard}</td>
                    <td><Quality value={obj.lineStatus} /></td>
                    <td>{obj.lotTitle}</td>
                    <td>{obj.operTitle}</td>
                    <td>{obj.userId}</td>
                    <td>{obj.vehicleTitle}</td>
                    <td>{obj.clientTitle}</td>
                    <td className="option">
                      <Link to={`/admin/lines/form/${obj.id}`}>
                        <button>Editar</button>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Template>
    </>
  )
}

export default Line;