import {React, useEffect} from "react";

export function ComboInputOutput({name, list, marked}) {
  
  useEffect(() => {
        
  }, []);

  const html = () => {

    var content;

    if(list.length > 0){

      content = list.map(item => {
        var check = item.id == marked
        
        return <span key={item.id}><input type="radio" 
                                          name={name}
                                          value={item.id}
                                          key={item.id} 
                                          defaultChecked={check}/>&nbsp;{item.title}</span>
      });
    }

    return content;
  }

  return (
    <>
      {html()}
    </>
  )  
}

export default ComboInputOutput;