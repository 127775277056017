import React, { useEffect, useState } from 'react';
import {useDispatch} from 'react-redux';

import { FaBuffer, FaCar, FaMotorcycle, FaTruck, FaTruckMoving, FaMobileAlt, FaStar } from "react-icons/fa";
import TreeMenu from 'react-simple-tree-menu';

import {BoxVehicle, InputSearch}  from './styles'

import Alert          from '@/modules/operacao/main/alerts';
import repository     from './repository';
import gridRepository from '@/modules/operacao/main/grid/repository';
import infoRepository from '@/modules/operacao/main/info/repository';

import parseResult from './comp/parseResult';

import gridAction from '@/store/grid/action';
import infoAction from '@/store/info/action';

function Vehicles() {

  const [list, setList]   = useState([]);
  const [total, setTotal] = useState({carro:0, caminhao:0, moto: 0, carreta: 0, fav: 0, movel: 0, total: 0});

  const dispatch          = useDispatch();

  const callType = (type) => {

    sendServer(type, "")
  }

  const callSearch = (event) => {

    sendServer("", event.target.value)
  }

  const callGridInfo = (item) => {
    
    var type      = item.type;
    var clientId  = 0;
    var vehicleId = 0;
    
    switch(item.type){
      case "CLIENT":
        clientId = item.key;
        break;
      case "VEHICLE":
        vehicleId = item.key.split("/")[1];
        break;
      default:
        break;
    }

    if(vehicleId > 0){
      
      infoRepository.getInfo(vehicleId, (response) => {
	
        const obj  = response.data;

        dispatch(infoAction(type, obj));
      });
    }
    else{

      dispatch(infoAction("EMPTY", {}));
      
      gridRepository.getGrid(clientId, (response) => {
	
        dispatch(gridAction(type, clientId, response.data));
      });
    } 
  }

  const sendServer = (vehicleType, param) => {

    repository.getAll(vehicleType, param, (response) => {
                
      var data = response.data;

      var result = {
        "contador": data.contador,
        "tree": parseResult(data.tree)
      }

      setList(result.tree)
      setTotal(result.contador);
    })
  }

  useEffect(() => {
    sendServer()
  }, []);

  return (
    <div className="form-group col-lg-4 col-xl-3 px-lg-2 order-1">
      <div className="card p-2 h100">
        <ul className="menu-vehicle">
          <li onClick={() => callType(0)}>
            <div className="icon-box">
              <FaBuffer size={20} />
            </div>
            <hr />
            {total.total}
          </li>
          <li onClick={() => callType(1)} id="carro">
            <div className="icon-box">
              <FaCar size={20} />
            </div>
            <hr />
            {total.carro}
          </li>
          <li onClick={() => callType(2)}>
            <div className="icon-box">
              <FaMotorcycle size={20} />
            </div>
            <hr />
            {total.moto}
          </li>
          <li onClick={() => callType(3)}>
            <div className="icon-box">
              <FaTruck size={20} />
            </div>
            <hr />
            {total.caminhao}
          </li>
          <li onClick={() => callType(4)}>
            <div className="icon-box">
              <FaTruckMoving size={20} />
            </div>
            <hr />
            {total.carreta}
          </li>
          <li onClick={() => callType(5)}>
            <div className="icon-box">
              <FaMobileAlt size={20} />
            </div>
            <hr />
            {total.movel}
          </li>
          <li onClick={() => callType(6)}>
            <div className="icon-box">
              <FaStar size={20} />
            </div>
            <hr />
            {total.fav}
          </li>
        </ul>

        <BoxVehicle>
          <TreeMenu
            hasSearch={false}
            onClickItem={callGridInfo}
            data={list}    
            />
        </BoxVehicle>
        
        <div className="row no-gutters">
          <div className="col-12">
            <InputSearch onChange={callSearch} placeholder="Digite para buscar" className="form-control" />
          </div>
        </div>
        
        <Alert />
      </div>
    </div>
  );
}

export default Vehicles;