import customApi from "@/componentes/api";

const URLS_LIST = {
  DEV: "https://run.mocky.io/v3/9b6f1c8d-fcea-4b72-a683-453bd77b6392",
  HOMOLOG: "http://localhost:8080/api/operation/report/control",
  PROD: "/api/operation/report/control"
}

function getList(params, callback) {

  customApi.get(URLS_LIST, params, callback);
}

export default {
  getList
};