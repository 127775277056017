import React from 'react';
import styled from 'styled-components'
import { AiFillHome } from "react-icons/ai";
import { Link } from 'react-router-dom'
import logo from  '@/assets/img/wisetrack_logo.png';

const HeaderComp = styled.div`
  width: 100%;
  height: 60px;
  background-color: #fff;
  padding: 10px;
  vertical-align: middle;
  border-top: 1px #D70000 solid;
  border-bottom: 1px #D70000 solid;

  .logo{
    float: left;
    width: 70%;
  }

  .back{
    float: right;
    width: 30%;
    text-align: right;
  }

`;

const IconOperation = styled(AiFillHome)`
    width: 40px;
    border: 2px red solid;
    background-color: #D70000;
    color: #fff;
    margin: 4px;
    padding: 2px;
    border-radius: 5px;
`;

export function Header() {

  return (
      <HeaderComp>
        <div className="logo">
          <img src={logo} alt="Wisetrack" />
        </div>
        <div className="back">
          <span>
            <Link to="/app">
              <IconOperation size={30} />
            </Link>
          </span>
        </div>
      </HeaderComp>
  )  
}

export default Header;