const INITIAL_STATE = {
    vehicleId: 0,
    lastInfo: {},
    list: [],
    cercas: [],
    events: [],
    comando: [],
    alerta: [],
    lines: [],
    hasData: false
  };

  export default function (state = INITIAL_STATE, action) {
      
    switch (action.type) {
      case "VEHICLE":
        return {lastInfo: action.payload.informacao[0], 
                list: action.payload.informacao,
                cercas: action.payload.cercas, 
                events: action.payload.events, 
                comando: action.payload.comando,
                alerta: action.payload.alerta,
                lines: action.payload.lines,
                hasData: true};
      case "EMPTY":
        return INITIAL_STATE;
      default:
          return state
    }
  }