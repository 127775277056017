import customApi from "@/componentes/api";

const URLS_SAVE = {
  DEV: "https://run.mocky.io/v3/bd94ff8e-7d58-4020-945e-654c6329edb2",
  HOMOLOG: "http://localhost:8080/api/operation/user/pass",
  PROD: "/api/operation/user/pass"
}

function save(obj, callback) {

  customApi.post(URLS_SAVE, obj, callback);
}

export default {
  save
};