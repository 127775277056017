import React, { useEffect, useState } from "react";

import { Link } from 'react-router-dom'
import alertify from 'alertifyjs';

import repository from './repository';
import { Form, IconList } from '@/componentes/form/styles';
import Template from '@/modules/admin/template';
import useForm from '@/componentes/form/useForm';
import Quality    from '@/componentes/quality';

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import ComboIntervaloTimeout from '@/modules/admin/combos/timeout';
import ComboSleepTime from '@/modules/admin/combos/sleepTime';
import ComboInputOutput from '@/modules/admin/combos/input_output';

export function DeviceActiveForm(props) {

    const url = "/admin/device/actives";

    const dataInitial = {
        userId: "",
        sleepTime: 0,
        timeout: 0,
        period: 0,   
        qtdInput: 0,
        qtdOutput: 0,    
        lines: [],
        vehicles: [],
        inputs: [],
        outputs: [],
        currentVehicle: 0,
        currentLine: 0,
        pos: []
    };

    const { values, setValues, handleChange, clearForm } = useForm(dataInitial);
    const [deviceId, setDeviceId]             = useState(0);
    const [currentLine, setCurrentLine]       = useState(0);
    const [currentVehicle, setCurrentVehicle] = useState(0);
    
    useEffect(() => {

        if (props.match.params.id) {

            setDeviceId(props.match.params.id)

            repository.getView(props.match.params.id, (response) => {
                setValues(response.data);
                setCurrentVehicle(response.data.currentVehicle)
                setCurrentLine(response.data.currentLine)
            });
        }

    }, [props.match.params.id, setValues]);

    const handleSubmit = (e) => {

        e.preventDefault();

        var obj = {};
        
        obj.deviceId    = deviceId
        obj.userId      = values.userId
        obj.sleepTime   = values.sleepTime;
        obj.timeout     = values.timeout;
        obj.period      = values.period;    
        obj.line        = currentLine;
        obj.vehicle     = currentVehicle;
        obj.inputs      = getEs("input", values.qtdInput) 
        obj.outputs     = getEs("output", values.qtdOutput) 

        repository.save(obj, (response) => {

            alertify.success(response.data.message);

            clearForm();

            props.history.push(url);
        });
    }
    
    const addLine = (e) => {
        setCurrentLine(e.target.value)
    };

    const removeLine = () => {

        setCurrentLine(0)
       
        var ele = document.getElementsByName("lineId");

        for(var i=0;i<ele.length;i++)
           ele[i].checked = false;
    };

    const addVehicle = (e) => {
        setCurrentVehicle(e.target.value)
    };

    const removeVehicle = () => {

        setCurrentVehicle(0)
       
        var ele = document.getElementsByName("vehicleId");

        for(var i=0;i<ele.length;i++)
           ele[i].checked = false;
    };

    const comboEs = (input) => {

        var arr, name;
        
        if(input){
            arr = values.inputs
            name= "input"
        }
        else{
            arr = values.outputs
            name= "output"
        }
        
        return arr.map((item, index) => {

            var inputName = name+"Port"+item.port;

            return <div className="line_es" key={index}>
                    <div className="row">
                        <div className="col-sm-1">
                            <h2 className="port">{item.port}</h2>
                        </div>
                        <div className="col-sm-11">
                            <ComboInputOutput name={inputName} 
                                            list={item.list} 
                                            marked={item.esId} />
                        </div>
                    </div>
                </div>
        })
    }

    const getEs = (name, qtdPorts) => {

        var arrPorts = []

        for(var port=1; port <= qtdPorts; port++){

            var ele = document.getElementsByName(name+"Port"+port);

            for (var i = 0; i < ele.length; i++) {
                
                if (ele[i].checked) {

                    var line = {}

                    line.port = port;
                    line.esId = ele[i].value
                    
                    arrPorts.push(line)
                }
            }
        }

       return arrPorts;
    }

    return (
        <>
            <Template title="Ativação de Equipamentos">

                <Form onSubmit={handleSubmit}>
                    <main className="container">

                        <div className="row">
                            <div className="col-sm-12">
                                
                                <Link to={url}>
                                    <IconList size={30} />
                                </Link>
                            </div>
                        </div>

                        <Tabs defaultActiveKey="device" id="controlled-tab">
                            <Tab eventKey="device" title="Equipamento">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <label htmlFor="userId">UserId *</label>
                                        <input type="text"
                                                name="userId"
                                                id="userId"
                                                maxLength="20"
                                                value={values.userId}
                                                onChange={handleChange} />
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="sleepTime">Sleep Time</label>
                                        <ComboSleepTime handle={handleChange} name="sleepTime" value={values.sleepTime} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <label htmlFor="timeout">Timeout *</label>
                                        <ComboIntervaloTimeout handle={handleChange} name="timeout" value={values.timeout} />
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="period">Intervalo *</label>
                                        <ComboIntervaloTimeout handle={handleChange} name="period" value={values.period} />
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="port" title="Portas">

                                <div className="row">
                                    <div className="col-sm-12">
                                        <label>Entradas</label>
                                    </div>
                                    <div className="col-sm-12">
                                      {comboEs(true)}
                                    </div>
                                    <div className="col-sm-6">
                                        <label>Saídas</label>
                                    </div>
                                    <div className="col-sm-12">
                                        {comboEs(false)}
                                    </div>
                                </div>

                            </Tab>
                            <Tab eventKey="line" title="Linhas">

                                <table className="table table-striped table_grid">
                                    <thead>
                                        <tr>
                                            <th>Número</th>
                                            <th>Simcard</th>
                                            <th>Lote</th>
                                            <th>Operadora</th>
                                            <th className="option">
                                                <Link onClick={removeLine} className="button_remove">Remover Linha</Link>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {values.lines &&
                                            values.lines.map((obj, index) => (
                                                <tr key={index}>
                                                    <td>{obj.number}</td>
                                                    <td>{obj.simcard}</td>
                                                    <td>{obj.lotTitle}</td>
                                                    <td>{obj.operTitle}</td>
                                                    <td className="option">
                                                    <input type="radio" 
                                                            name="lineId" 
                                                            id={"lineId"+index} 
                                                            value={obj.lineId}
                                                            alt={index}
                                                            defaultChecked={obj.deviceId > 0}
                                                            onChange={(e) => addLine(e)} />
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </Tab>
                            <Tab eventKey="vehicle" title="Veículos">
                                <table className="table table-striped table_grid">
                                    <thead>
                                        <tr>
                                            <th>Veículo</th>
                                            <th>Placa</th>
                                            <th>Cliente</th>
                                            <th>Veículo ?</th>
                                            <th className="option">
                                                <Link onClick={removeVehicle} className="button_remove">Remover Veiculo</Link>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {values.vehicles &&
                                            values.vehicles.map((obj, index) => (
                                                <tr key={index}>
                                                    <td>{obj.vehicleTitle}</td>
                                                    <td>{obj.plate}</td>
                                                    <td>{obj.clientTitle}</td>
                                                    <td><Quality value={obj.vehicle} /></td>
                                                    <td className="option">
                                                        <input type="radio" 
                                                                name="vehicleId" 
                                                                id={"vehicleId"+index} 
                                                                value={obj.vehicleId}
                                                                alt={index}
                                                                defaultChecked={obj.vehicleDeviceId > 0}
                                                                onChange={addVehicle} />
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </Tab>

                            <Tab eventKey="position" title="Rastreamento">
                                <table className="table table-striped table_grid">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Latitude</th>
                                            <th>Longitude</th>
                                            <th>Data Evento</th>
                                            <th>Data Sistema</th>
                                            <th>Endereço</th>
                                            <th>Param</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {values.pos &&
                                            values.pos.map((obj, index) => (
                                                <tr key={index}>
                                                    <td>{obj.id}</td>
                                                    <td>{obj.latitude}</td>
                                                    <td>{obj.longitude}</td>
                                                    <td>{obj.datetime}</td>
                                                    <td>{obj.received}</td>
                                                    <td>{obj.address}</td>
                                                    <td>{obj.param}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </Tab>
                        </Tabs>

                        <div className="row">
                            <button>Salvar</button>
                        </div>
                    </main>
                </Form>
            </Template>
        </>
    )
}

export default DeviceActiveForm;