import customApi from "@/componentes/api";

const URLS_SAVE = {
  DEV: "https://run.mocky.io/v3/bd94ff8e-7d58-4020-945e-654c6329edb2",
  HOMOLOG: "http://localhost:8080/api/admin/clients",
  PROD: "/api/admin/clients"
}

const URLS_GET = {
  DEV: "https://run.mocky.io/v3/274d4ae2-2cd2-444f-bb29-9f85eb82fcbc",
  HOMOLOG: "http://localhost:8080/api/admin/clients/:id",
  PROD: "/api/admin/clients/:id"
}

const URLS_LIST = {
  DEV: "https://run.mocky.io/v3/398fc038-833e-4525-af4d-f0af53ea2821",
  HOMOLOG: "http://localhost:8080/api/admin/clients",
  PROD: "/api/admin/clients"
}

const URLS_COMBO = {
  DEV: "https://run.mocky.io/v3/6edc6642-21d2-44f4-b4ca-a0e939abc172",
  HOMOLOG: "http://localhost:8080/api/admin/clients/combo",
  PROD: "/api/admin/clients/combo"
}

function getList(param, callback) {

  customApi.get(URLS_LIST, param, callback);
}

function getView(id, callback) {

  var params = {":id": id};

  customApi.getPath(URLS_GET, params, callback);
}

function getCombo(callback) {

  var params = {};

  customApi.get(URLS_COMBO, params, callback);
}

function save(obj, callback) {

  customApi.post(URLS_SAVE, obj, callback);
}

export default {
  getList, getView, save, getCombo
};