import styled from 'styled-components'

import { FaSearchPlus } from "react-icons/fa";

export const SearchButton = styled(FaSearchPlus)`
    width: 60px;
    height: 30px;
    border: 2px red solid;
    background-color: #D70000;
    color: #fff;
    margin: 4px;
    padding: 2px;
    border-radius: 5px;
    float: right;
    cursor: pointer;
`;
