export default function(type, info) {

    return {
      type: type,
      payload:{
        informacao: info.informacao,
        cercas: info.cercas,
        events: info.eventos,
        comando: info.comando,
        alerta: info.alerta,
        lines: info.linhas
      }
    };
  }