import React from 'react';

import Quality  from '@/componentes/quality';
import { FaRoute} from "react-icons/fa";

function Cercas({
    items
}) {

    let cercas;

    if(items){

        cercas = 
        items.map((cerca, index) => {
                return <tr key={index}>
                    <td className="brd-right">
                        {cerca.titulo}
                    </td>
                    <td className="text-center">
                        <span id="status">
                            <Quality value={cerca.status} />
                        </span>
                    </td>
                </tr>;
        });
    }

    return (
         <div className="card-white half-card">
            <table className="table table-striped mb-0">
                <thead>
                    <tr>
                        <th className="brd-right">
                            <FaRoute /> Cercas
                        </th>
                        <th className="text-center">
                            Status
                        </th>
                    </tr>
                </thead>
                <tbody>{cercas}</tbody>
            </table>
        </div>
    );
  }

export default Cercas;