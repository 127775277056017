import { React } from "react";

export function CheckAll({ arrCheckName, handleAll}) {

  const checkAll = (e) => {

      var value  = false;
      var checkedItems = [];

      if(e.target.checked){
        value = true;
      }
      
      Array.from(document.querySelectorAll("input[name="+arrCheckName+"]"))
           .forEach((checkbox) => {
            document.getElementById(checkbox.id).checked = value;

            if(value){
              checkedItems.push(checkbox.value);
            }
           });

      handleAll(checkedItems);
  }

  return (
    <>
      <div className="inputSelectAll">
        <span>Todos:</span> <input type="checkbox" onChange={checkAll} />
      </div>
    </>
  )
}

export default CheckAll;