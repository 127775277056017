import validator from 'validator';
class ValidateFields {
 
  
  validateUser(user) {

    if (validator.isEmpty(user)) {
      return 'Login é obrigatório';
    } 

    return false;
  }

  validatePassword(password) {
    
    if (validator.isEmpty(password)) {

      return 'Senha é obrigatória';
    } 

    return false;
  }
}

const validateFields = new ValidateFields();

export { validateFields };