const INITIAL_STATE = {
    user: {}
  };

  export default function (state = INITIAL_STATE, action) {
      
    switch (action.type) {
      case "LOGIN":
        return {user: {username: action.payload.username, 
                       client: action.payload.client,
                       cliType: action.payload.cliType}};
      case "LOGOUT":
        return INITIAL_STATE;
      default:
          return state
    }
  }