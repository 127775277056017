import customApi from "@/componentes/api";

const URLS_GET = {
  DEV: "https://run.mocky.io/v3/274d4ae2-2cd2-444f-bb29-9f85eb82fcbc",
  HOMOLOG: "http://localhost:8080/api/admin/clients/selected/:id",
  PROD: "/api/admin/clients/selected/:id"
}

function getView(id, callback) {

  var params = {":id": id};

  customApi.getPath(URLS_GET, params, callback);
}

export default {
  getView
};