import React, {useEffect } from "react";

import { Link } from 'react-router-dom'
import alertify from 'alertifyjs';

import repository         from './repository';
import { Form, IconList } from '@/componentes/form/styles';
import Template           from '@/modules/admin/template';
import useForm            from '@/componentes/form/useForm';

export function GroupForm(props) {

    const url = "/admin/groups";

    const dataInitial = {
        title: "",
        status: true,
        id: 0
    };

    const {values, setValues, handleChange, clearForm} = useForm(dataInitial);
    
    useEffect(() => {

        if (props.match.params.id) {
            
            repository.getView(props.match.params.id, (response) => {

                setValues(response.data);                
            });
        }
   
    }, [props.match.params.id, setValues]);

    const handleSubmit = (e) => {

        e.preventDefault();

        repository.save(values, (response) => {

            alertify.success(response.data.message);
    
            clearForm();
    
            props.history.push(url);
        });
    }

    return (
        <>
            <Template title="Grupos">

                <Form onSubmit={handleSubmit}>
                    <main className="container">
                        
                        <div className="row">
                            <div className="col-sm-12">
                                <Link to={url}>
                                    <IconList size={30} />
                                </Link>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6">
                                <label>Titulo</label>
                                <input type="text" 
                                        name="title" 
                                        id="title" 
                                        maxLength="50" 
                                        value={values.title}
                                        onChange={handleChange} />
                            </div>
                            <div className="col-sm-6">
                                <label htmlFor="status">Status</label>
                                <select value={values.status} onChange={handleChange} name="status" id="status" >
                                    <option value={true}>Ativo</option>
                                    <option value={false}>Desativado</option>
                                </select>
                            </div>
                        </div>
                                                                           
                        <div className="row">
                            <button>Salvar</button>
                        </div>
                    </main>
                </Form>
            </Template>
        </>
    )
}

export default GroupForm;