import styled from 'styled-components'

import { FiMenu } from "react-icons/fi";

 export const MenuComp = styled.div`

    overflow: hidden;
    font-size: 14px;
    color: #fff;
    background-color: #D70000;

    a {
        float: left;
        display: block;
        text-align: center;
        padding: 10px 12px;
        text-decoration: none;
        border-right: 1px #fff solid;
    }

    a:hover {
        background-color: #F08080;
        font-weight: bold;
    }

    a.active {
        background-color: #4CAF50;
    }

    .icon {
        display: none;
    }

    @media screen and (max-width: 600px) {
        a:not(:first-child) {
            display: none;
            border-right: 0px;
        }
    
        a.icon {
            float: right;
            display: block;
        }
    }

    @media screen and (max-width: 600px) {

        .responsive{
            position: relative;
        }

        .responsive .icon {
            position: absolute;
            right: 0;
            top: 0;
        }

        .responsive a {
            float: none;
            display: block;
            text-align: left;
        }

        .responsive a:hover {
            background-color: #F08080;
            font-weight: bold;
        }
    }
`;

export const MenuResponsive = styled(FiMenu)`
    width: 40px;
    border: 2px #fff solid;
    background-color: #D70000;
    color: #fff;
    padding: 2px;
    border-radius: 5px;
`;