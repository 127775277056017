import axios from 'axios';
import alertify from 'alertifyjs';

const api = axios.create({});

api.interceptors.request.use(async config => {

    config.headers.Accept = `application/json`;

    return config;
});

/**
 * Função padrão em caso de erros
 * 
 * @param {} error 
 */
const DEFAULT_ERROR = (error) => {
    
    if(error != null && error.response != null){
        
        if(error.response.status === 401){

            var url = getUrl(process.env.REACT_APP_BASEDIR)

            window.location.href = `${url}/`;
        }
        else if(error.response.status === 400){

            var content = "";

            if(Array.isArray(error.response.data)){

                error.response.data.forEach(obj => {
                    content += `<li>${obj.message}</li>`;
                });
    
                alertify.alert('Atenção Erros', `<div><ul>${content}</ul></div>`);
            }
            else{
                alertify.error(error.response.data.message);
            }
        }
        else{
            
            var result = error.response.data

            if(result.message === undefined){

                alertify.alert("Erros na solicitação ao servidor.");
            }
            else{
                alertify.alert(`<center><b>Atenção erros na solicitação</b> <br /><br /> ${result.message}.</center>`);
            }
        }
    }
};

const headers = {
    'Content-Type': 'application/json'
};

const defineEnv = (url_arr) => {

    if(process.env.REACT_APP_ENV === "dev"){
        return url_arr.DEV;
    }
    else if(process.env.REACT_APP_ENV === "homolog"){
        return url_arr.HOMOLOG;
    }
    else {
        
        var domain = getUrl(process.env.REACT_APP_BASEDIR)

        return `${domain}${url_arr.PROD}`;
    }
}

const getUrl = (context) => {

    var domain = window.location

    var port = domain.port

    if(port !== "" && port !== undefined){
        port = `:${port}`
    }
    else{
        port = ""
    }

    if(context !== "" && context !== undefined){
        context = `/${context}`
    }
    else{
        context = ""
    }
    
    return `${domain.protocol}//${domain.hostname}${port}${context}`
}

class DisplayLoader{

    show(){

        var myElement = document.querySelector(".loader");

        if(myElement !== null){
            myElement.style.display = "block";
        }
    }

    hide(){
        
        var myElement = document.querySelector(".loader");

        if(myElement !== null){
            myElement.style.display = "none";      
        }  
    }
}

class CustomApi {

    constructor(){
        this.display = new DisplayLoader();
    }
    
    post(url_arr, param, success){

        this.display.show();

        api.post(defineEnv(url_arr), param, headers)
            .then(success)
            .catch(DEFAULT_ERROR)
            .finally(() =>  this.display.hide());
    }

    get(url_arr, param, success){

        this.display.show();

        var params = {'params': param};

        api.get(defineEnv(url_arr), params)
            .then(success)
            .catch(DEFAULT_ERROR)
            .finally(() => this.display.hide());
    }

    getPath(url_arr, pathParam, success){

        this.display.show();

        var url = defineEnv(url_arr);

        for (var key in pathParam) {
            url = url.replace(key, pathParam[key]);
        }

        api.get(url, headers)
            .then(success)
            .catch(DEFAULT_ERROR)
            .finally(() => this.display.hide());
    }

    put(url_arr, pathParam, param, success){

        this.display.show();

        var url = defineEnv(url_arr);

        for (var key in pathParam) {
            url = url.replace(key, pathParam[key]);
        }

        api.put(url, param, headers)
            .then(success)
            .catch(DEFAULT_ERROR)
            .finally(() =>  this.display.hide());
    }
}

const customApi = new CustomApi();

export default customApi;