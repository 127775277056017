import React from 'react';

import Quality  from '@/componentes/quality';

import {MdEvent} from "react-icons/md";

function Events({
    events
}) {
    
    let eventsText;

    if(events){

        eventsText = events.map((event, index) => {

            return <tr key={index}>
                <td className="brd-right">
                    {event.titulo}
                </td>
                <td className="text-center">
                    <span id="status">
                        <Quality value={event.status} />
                    </span>
                </td>
            </tr>;
        });
    }

    return (

        <div className="card-white half-card ml1">
            <table className="table table-striped mb-0">
                <thead>
                    <tr>
                        <th className="brd-right">
                            <MdEvent /> Eventos
                        </th>
                        <th className="text-center">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {eventsText}
                </tbody>
            </table>
        </div>
    );
}
export default Events;