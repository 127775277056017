import {React, useEffect, useState} from "react";

import repository from '@/modules/admin/pages/line_lot/repository';

export function ComboLineLot({handle, name, value}) {
    
  const [list, setList] = useState([]);

  useEffect(() => {
    
    repository.getCombo((response) => {
      setList(response.data);
    });

  }, []);

  const html = () => {

    var content;

    if(list.length > 0){

      content = list.map(item => {
        return <option key={item.id} value={item.id}>{item.title}</option>
      });
    }

    return content;
  }

  return (
    <>
      <select onChange={handle} name={name} value={value}>
        <option value="0">-Selecione-</option>
        {html()}
      </select>
    </>
  )  
}

export default ComboLineLot;