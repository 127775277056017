import React, { useState, useEffect } from 'react';
import {useSelector} from 'react-redux'

import Car from '@/assets/img/markers/car.png';
import Moto from '@/assets/img/markers/motorcycle.png';
import Truck from '@/assets/img/markers/truck3.png';
import Carreta from '@/assets/img/markers/truck3.png';
import Movel from '@/assets/img/markers/carrental.png';

import {Map, Marker, GoogleApiWrapper, Polyline, InfoWindow} from 'google-maps-react';

const mapaProps = {

    mapaCenterInicial: {
        lat: -14.179186,
        lng: -50.449219
    },
    mapaZoomInicial: 3,
    mapaZoomAlto: 8,
    mapaZoomBaixo: 16,
    initialBounds: [{latitude: 2.826914, longitude: -60.679055}, 
                    {latitude: -32.236485, longitude: -53.081257}]
};

export function Mapa(props){

    const grid = useSelector(state  => state.grid.list)
    const info = useSelector(state  => state.info.list)

    const [bounds, setBounds]           = useState({})
    const [markers, setMarkers]         = useState([])
    const [points, setPoints]           = useState([])
    
    const [activeMarker, setActiveMarker]  = useState({})
    const [selectedPlace, setSelectedPlace]  = useState({})
    const [showingInfoWindow, setShowingInfoWindow]  = useState(false)

    const onMarkerClick = (propsLocal, marker) => {
        setActiveMarker(marker);
        setSelectedPlace(propsLocal);
        setShowingInfoWindow(true);
    };

    const onInfoWindowClose = () => {
        setActiveMarker(null);
        setShowingInfoWindow(false);
    }

    const onMapClicked = () => {
        
        if (showingInfoWindow){
            setActiveMarker(null);
            setShowingInfoWindow(false);
        }
    }

    const createIconMarker = (obj) => {

      switch(obj.tipoVeiculo){
        case 2:
        return Moto;
        case 3:
        return Truck;
        case 4:
        return Carreta;
        case 5:
        return Movel;
        default:
        return Car; 
      }
    }

    const createInfowindow = (obj) => {

        return <table cellSpacing="2" cellPadding="3" className="info_window">
            <tr>
                <th>Veiculo</th>
                <th>Velocidade</th>
            </tr>
            <tr>
               <td>{obj.tituloVeiculo}</td>
                <td>{obj.speed} Km/h</td>
            </tr>
            <tr>
                <th>Data Sistema</th>
                <th>Data Evento</th>
            </tr>
            <tr>
                <td>{obj.received}</td>
                <td>{obj.datetime}</td>
            </tr>
            <tr>
                <th>Latitude</th>
                <th>Longitude</th>
            </tr>
            <tr>
                <td>{obj.latitude}</td>
                <td>{obj.longitude}</td>
            </tr>
            <tr>
                <th colSpan="2">Prox.</th>
            </tr>
            <tr>
                <td colSpan="2">{obj.address}</td>
            </tr>
        </table>
    }

    useEffect(() => {
       
        var data = [];
        var type = "";

        if(info.length > 0){
            data = info
            type = "INFO"
        }
        else if(grid.length > 0){
            data = grid;
            type = "GRID";
        }
        
        clearMap();

        //Markers e Infos
        const currentMarkers = []

        data.forEach((row, index) => {

            var latLng = {lat: row.latitude, lng: row.longitude}

            var animation = "";

            if(index === 0  && type === "INFO"){
                animation = props.google.maps.Animation.BOUNCE
            }

            currentMarkers.push(<Marker key={index} 
                                        name={createInfowindow(row)} 
                                        title={row.tituloVeiculo} 
                                        position={latLng} 
                                        onClick={onMarkerClick}
                                        animation={animation}
                                        icon={createIconMarker(row)}/>);
        });

        setMarkers(currentMarkers)

         //Bounds
         var currentBounds = new props.google.maps.LatLngBounds();
        
         var dataBounds = data.length > 0 ? data : mapaProps.initialBounds;

         dataBounds.forEach(row => {    
             currentBounds.extend({lat: row.latitude, lng: row.longitude});
         });
     
         setBounds(currentBounds);

         //Polyline
         if(type === "INFO"){

            const points = data.map(row => {
                return {lat: row.latitude, lng: row.longitude}
            });

            setPoints(points)
         }
               
    }, [grid, info]);

    const clearMap = () => {
        setBounds([])
        setMarkers([])
        setPoints([])
    }

    return (
        <>
           <Map google={props.google}
                zoom={mapaProps.mapaZoomInicial}
                onClick={onMapClicked}
                style={{height: '495px', width: '100%'}}
                initialCenter={mapaProps.mapaCenterInicial}
                bounds={bounds}>
                {markers}

                {markers.length > 0 &&
                  <InfoWindow  marker={activeMarker} onClose={onInfoWindowClose} visible={showingInfoWindow}>
                      {selectedPlace.name}
                  </InfoWindow>}
            
                <Polyline path={points}
                          strokeColor="#ff0000"
                          strokeOpacity={0.8}
                          strokeWeight={4} />

            </Map> 
        </>
    );
}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_MAP_KEY
})(Mapa)