import React from 'react'
import { useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';

import { Link } from 'react-router-dom'

import { AiOutlineReload }  from "react-icons/ai";
import { FaUserAlt }        from "react-icons/fa";
import { HiDocumentReport } from "react-icons/hi";
import { ImProfile }        from "react-icons/im";

import gridAction from '@/store/grid/action';
import infoAction from '@/store/info/action';
import userAction from '@/store/user/action';

import repository from './repository'

import Pass from './comp/pass'
import Logout from './comp/logout'

import Tooltip from 'rc-tooltip';

export function Menu() {

    const user  = useSelector(state => state.user.user)

    const dispatch = useDispatch();

    const fnClear = () => {
      
      dispatch(gridAction("EMPTY"));
      dispatch(infoAction("EMPTY", {}));
    }

    const sendServer = () => {

        repository.getPrincipal((response) => {

            dispatch(userAction("LOGIN", response.data));
        });
    }
   
    const showAdmin = () => {

        if(user.cliType === 1){
            return true;
        }
        
        return false;
    }
    
    useEffect(() => {
        
        sendServer();

    }, []);

    return (

        <ul className="header-nav__menu" id="mainMenu">
            <li className="mlr-5">
                <div className="box-user">
                    <div>
                        <FaUserAlt size={30} />
                    </div>
                    <div className="ml-2">
                        <strong>Usuário:</strong> { user.username }<br />
                        <strong>Empresa:</strong> { user.client }
                    </div>
                </div>
            </li>
            <li className="icon-operacao" title="">
                <Tooltip placement="bottom" overlay="Limpar Informações">
                    <Link to="/app" onClick={fnClear} className="item-menu">
                        <AiOutlineReload size={30} />
                    </Link>
                </Tooltip>
            </li>
            <li title="Alterar Senha ?">
                <Pass />
            </li>
            <li className="icon-operacao" title="">
                <Tooltip placement="bottom" overlay="Relatorios">
                    <Link to="/report/position" className="item-menu">
                        <HiDocumentReport size={30} />
                    </Link>
                </Tooltip>
            </li>
            
            <Logout />
            
            <li className="icon-operacao icon-admin" style={{display: showAdmin() ? 'block' : 'none' }}>
                <Tooltip placement="bottom" overlay="Administração">
                    <Link to="/admin" className="item-menu">
                        <div>
                            <ImProfile size={28} />
                        </div>
                        <span>Admin</span>
                    </Link>
                </Tooltip>
            </li>
        </ul>
    );
}

export default Menu;

