import React from 'react';

import { FaCar, FaMotorcycle, FaTruck, FaTruckMoving, FaMobileAlt } from "react-icons/fa";

export default function VehicleType({
  value
}) {

  let type

  switch(value){
      case 2:
        type =  <FaMotorcycle />
        break;
      case 3:
        type =  <FaTruck />
        break;
      case 4:
        type =  <FaTruckMoving  />
        break;
      case 5:
        type =  <FaMobileAlt />
        break;
     default:
         type =  <FaCar />
  }
  
  return (
    <>
      {type}
    </>
  );
}