import React from 'react'
import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';

import { HiOutlineLogout } from "react-icons/hi";

import gridAction from '@/store/grid/action';
import infoAction from '@/store/info/action';
import userAction from '@/store/user/action';

import repository from './repository'

import Tooltip from 'rc-tooltip';

export function Logout() {

    const history = useHistory()

    const dispatch = useDispatch();

    const fnRealizeLogout = () => {

        repository.logout((response) => {

            dispatch(userAction("LOGOUT", {}));
            dispatch(gridAction("EMPTY"));
            dispatch(infoAction("EMPTY", {}));

            history.push("/signin")
        })
    }

    return (

        <li className="icon-operacao" title="">
            <Tooltip placement="bottom" overlay="Clique para Sair">
                <a onClick={fnRealizeLogout} className="item-menu">
                    <div>
                        <HiOutlineLogout size={30} />
                    </div>
                    <span>SAIR</span>
                </a>
            </Tooltip>
        </li>
    );
}

export default Logout;

