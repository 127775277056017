import PropTypes from "prop-types";
import React from "react";

import { FaToggleOn, FaToggleOff } from "react-icons/fa";

import Tooltip from 'rc-tooltip';

export class ButtonPower extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        const { actual, toggleSelected } = this.props;

        var icon;
        var msgTooltip;

        if(actual > 0){
           icon = <FaToggleOn size="18" />
           msgTooltip = "Clique para Desativar"
        }
        else{
            icon = <FaToggleOff size="18" />
            msgTooltip = "Clique para Ativar"
        }

        return (
            <div>
                <Tooltip placement="bottom" overlay={msgTooltip}>
                    <div className="toggle-container link" onClick={toggleSelected}>
                        {icon}
                    </div>
                </Tooltip>
            </div>
        );
    }
}

ButtonPower.propTypes = {
    actual: PropTypes.bool.isRequired,
    toggleSelected: PropTypes.func.isRequired
};