import styled from 'styled-components'

import { BsGrid3X3GapFill } from "react-icons/bs";

 export const Form = styled.form`

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding: 20px;
        gap: 30px;
    }

    .container input[type="text"], .container select,  .container input[type="password"]{
        width: 100%;
        box-shadow: none;
        border-radius: 5px;
        transition: .5s;
        font-size: 16px;
        font-weight: bold;
        padding: 0 10px;
        border: 2px RED solid;
    }

    .container label {
        left: 10px;
        margin-bottom: 5px;
        font-size: 16px;
        color: #D70000;
        transition: .5s;
        cursor: text;
    }

    .container .radioitem{
        margin-right: 10px;
        font-size: 16px;
    }
    
   .container button {
        margin: 0 auto;
        text-align: center;
        width: 200px;
        height: 50px;
        border:none;
        outline: none;
        cursor: pointer;
        background-color: #D70000;
        font-size: 20px;
        font-weight: bold;
        border-radius: 8px;
        color: #fff;
    }
`;

export const IconList = styled(BsGrid3X3GapFill)`
    width: 40px;
    border: 2px red solid;
    background-color: #D70000;
    color: #fff;
    margin: 4px;
    padding: 2px;
    border-radius: 5px;
    float: right;
`;