import customApi from "@/componentes/api";

const URLS_LIST = {
 // DEV: "https://run.mocky.io/v3/d8be0fd5-f63d-48aa-b288-ce6869bb7aac",
  DEV: "https://run.mocky.io/v3/e8808f00-d509-4c7b-9c8a-65779031a13f",
  HOMOLOG: "http://localhost:8080/api/operation/alerts",
  PROD: "/api/operation/alerts"
}

const URLS_SAVE = {
  DEV: "https://run.mocky.io/v3/b816be99-59f6-4df4-9849-a9ad5f7dbcb0",
  HOMOLOG: "http://localhost:8080/api/operation/alerts/save",
  PROD: "/api/operation/alerts/save"
}

function getAlerts(types, callback) {
  
  customApi.post(URLS_LIST, types, callback);
}

function save(obj, callback) {

  customApi.post(URLS_SAVE, obj, callback);
}

export default {
  getAlerts,
  save
};