import React from "react";
import Template from '@/modules/admin/template';

export function Admin() {

  return (
    <>
       <Template title="Home" />
       <h3>Para utilizar as telas abaixo, selecione o Cliente desejado no combo.</h3>
       <ul>
         <li>Usuários</li>
         <li>Grupos</li>
         <li>Veículos</li>
         <li>Visualização</li>
       </ul>
    </>
  )  
}

export default Admin;