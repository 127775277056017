import { createStore, combineReducers } from 'redux';

import gridReducer from './grid/reducer'
import infoReducer from './info/reducer'
import userReducer from './user/reducer'

const rootReducer = combineReducers({
  grid: gridReducer,
  info: infoReducer,
  user: userReducer,
  start: userReducer
})

const store = createStore(rootReducer);

export default store;
