import React, { useState } from 'react';
import {useSelector, useDispatch} from 'react-redux'

import useInterval from '@/componentes/useInterval';

import { FaFileAlt } from "react-icons/fa";

import { Modal} from 'react-bootstrap';


import { InfoGrid, InfoGridItem} from './styles'

import infoRepository from '@/modules/operacao/main/info/repository';

import Cercas from '@/modules/operacao/main/info/comp/cercas';
import Events from '@/modules/operacao/main/info/comp/events';
import Line from '@/modules/operacao/main/info/comp/line';
import Command from '@/modules/operacao/main/info/comp/command';

import infoAction from '@/store/info/action';

const TIMER = 40000;

function Info() {

  const lastInfo  = useSelector(state  => state.info.lastInfo)
  const cercas    = useSelector(state  => state.info.cercas)
  const events    = useSelector(state  => state.info.events)
  const lines     = useSelector(state  => state.info.lines)
  const list      = useSelector(state  => state.info.list)

  const dispatch  = useDispatch();

  const [information, setInformation] = useState(false);

  const openInformation = () =>{
    setInformation(information ? false : true)
  }

  const htmlModal = () => {

    var content = ""

    if(lastInfo !== undefined && lastInfo.hasOwnProperty("tituloVeiculo")){

      content = 
      <div className="row">
          <div className="col-lg-12">
            <h3 className="d-inline">
              <span className="float-left">Informações</span>
              <a className="float-left d-inline ml-1" href="#" onClick={openInformation}>
                  <FaFileAlt /> 
              </a>
              
              <span className="float-right">{lastInfo.tituloVeiculo}</span>
              
                <Modal size="lg" show={information} onHide={openInformation}>
                  <Modal.Header closeButton>
                    <Modal.Title>Últimas Posições</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      
                    <div className="card-white mb-2">
                      <table className="table table-striped mb-0">
                        <thead>
                          <tr align="center">
                            <th className="brd-right">Dt Sistema</th>
                            <th className="brd-right">Dt Evento</th>
                            <th className="brd-right">Km/h</th>
                            <th className="brd-right">Prox.</th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.map((info, index) =>
                            <tr align="center" key={index}>
                              <td className="brd-right">
                                {info.received}
                              </td>
                              <td className="brd-right">
                                {info.datetime}
                              </td>
                              <td className="brd-right">{info.speed}</td>
                              <td className="brd-right">{info.address}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Modal.Body>
                </Modal>
            </h3>
          </div>
      </div>
    }
   
    return content;
  }

  const htmlContent = () => {
    
    var content = ""

    if(lastInfo !== undefined && lastInfo.hasOwnProperty("tituloVeiculo")){

      content = 
        <div className="card-white mb-2 p-2">
          <table className="table table-grid-info">
            <thead>
              <tr>
                <th>Identificação</th>
                <th>Placa</th>
                <th>Equipamento</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{lastInfo.tituloVeiculo}</td>
                <td>{lastInfo.placa}</td>
                <td>{lastInfo.tituloEquipamento}</td>
              </tr>
              <tr>
                <th>Latitude</th>
                <th>Longitude</th>
                <th>Data Sistema</th>
              </tr>
              <tr>
                <td>{lastInfo.latitude}</td>
                <td>{lastInfo.longitude}</td>
                <td>{lastInfo.received}</td>
              </tr>
              <tr>
                <th>Vel. Atual</th>
                <th>Vel. Máxima</th>     
                <th>Data Evento</th>           
              </tr>
              <tr>
                <td>{lastInfo.speed}</td>
                <td>{lastInfo.maxVelocidade}</td>
                <td>{lastInfo.datetime}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colSpan="4">Proximidade</th>
              </tr>
              <tr>
                <td colSpan="4">{lastInfo.address}</td>
              </tr>
            </tfoot>
          </table>
        </div>
    }

    return content;
  }

  useInterval(() => {

    var type = "";

    if(lastInfo.idVeiculo){
      type = "VEHICLE";
    }

    if(type !== ""){

      infoRepository.getInfo(lastInfo.idVeiculo, (response) => {
	
        const obj  = response.data;

        dispatch(infoAction(type, obj));
      });
    }
   
  }, TIMER);

  return (
    <div className="form-group col-lg-5 col-xl-5 px-lg-2 order-3 order-lg-2">
       <div className="card p-2 h100">
          <div>
            {htmlModal()}

            <InfoGrid style={lastInfo === undefined || !lastInfo.hasOwnProperty("tituloVeiculo")  ? { display: 'none' } : {}}>
              <InfoGridItem>
                {htmlContent()}
              </InfoGridItem>
              <InfoGridItem>
                <Cercas items={cercas} />
                <Events events={events} />
              </InfoGridItem> 
            </InfoGrid>
            
            <div>
              <ul className="menu-info">
                <li style={lastInfo === undefined || !lastInfo.hasOwnProperty("tituloVeiculo") ? { display: 'none' } : {}}>
                  <Line lines={lines} />
                </li>  
                <li style={{ display: 'none' }}>
                  <Command />
                </li>
              </ul>
            </div>
           
          </div>
        </div>
    </div>
  );
}

export default Info;