import React from 'react';

import styled from 'styled-components'
import Header from '@/modules/base/header';
import Menu from './menu'
import '../pagination.css'

import { IoNavigate } from "react-icons/io5";
import ImgLoader from '@/assets/img/loader/loader.gif';
import { Loader } from '@/componentes/loader/styles';

const Wrapper = styled.div`
  width: 100%;
`;

const Body = styled.div`
  padding: 15px;
`;

const Nav = styled.nav`
  font-size: 16px;
  font-weight: bold;
  vertical-align: middle;
  background-color: #E9ECEF;
  padding: 10px;
`;

export function Template(props) {

  return (
    <>
      <Wrapper>
        <Loader className="loader">
          <img src={ImgLoader} alt="" />
        </Loader>
        <Header />
        <Menu />
        <Nav>
          <div className="row">
            <div className="col-lg-6 col-xs-12">
              <IoNavigate />&nbsp;{props.title}
            </div>
          </div>
        </Nav>
        <Body>
          {props.children}
        </Body>
      </Wrapper>
    </>
  )
}

export default Template;