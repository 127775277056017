import React from 'react';
import styled from 'styled-components'

import { IoNavigate } from "react-icons/io5";

import ComboClient from '@/modules/admin/combos/client';

const Nav = styled.nav`
  font-size: 16px;
  font-weight: bold;
  vertical-align: middle;
  background-color: #E9ECEF;
  padding: 10px;
`;

export function Navigation({name}) {

  return (
    <>
      <Nav>
        <div className="row">
          <div className="col-lg-6 col-xs-12">
            <IoNavigate />&nbsp;{name}
          </div>
          <div className="col-lg-6 col-xs-12">
            <ComboClient />
          </div>
        </div>
      </Nav>
    </>
  )  
}

export default Navigation;