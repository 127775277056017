import React from 'react';
import {useSelector, useDispatch} from 'react-redux'

import useInterval from '@/componentes/useInterval';

import { FaHouzz,
         FaMapMarkedAlt,
         FaFlag } from "react-icons/fa";

import {GridRastreamento}   from './styles'
import Quality              from '@/componentes/quality';
import AlertFlag            from '@/componentes/alert_flag';
import VehicleType          from '@/componentes/vehicle_type';

import gridRepository from '@/modules/operacao/main/grid/repository';
import infoRepository from '@/modules/operacao/main/info/repository';
import gridAction     from '@/store/grid/action';
import infoAction     from '@/store/info/action';

import "./grid.css"

const TIMER = 100000

function Grid() {

  const clientId  = useSelector(state   => state.grid.clientId)
  const list      = useSelector(state   => state.grid.list)

  const dispatch  = useDispatch();
  
  const fnCallInfo = (vehicle) => {
    
    infoRepository.getInfo(vehicle.idVeiculo, (response) => {
	
      dispatch(infoAction("VEHICLE", response.data));
    });
  }

  const htmlGrid = () => {

    var content = <tr>
        <td className="grid-empty" colSpan="12">
          <FaFlag /> Nenhuma informação carregada.
        </td>
    </tr>

    if(list.length > 0){

      content = list.map(vehicle => {

        return <tr key={vehicle.idRastreamento} 
                   onClick={() => fnCallInfo(vehicle)} 
                   className={`line_link`}>
          <td align="left">
              {vehicle.tituloVeiculo}
          </td>
          <td align="center">
              <VehicleType value={vehicle.tipoVeiculo} />
          </td>
          <td align="center">
              {vehicle.speed}
          </td>
          <td align="center">
            <AlertFlag value={vehicle.alerta} />
          </td>
          <td align="center">
            <Quality value={vehicle.bloqueio} />
          </td>
          <td align="center">
            <Quality value={vehicle.ignicao} />
          </td>
          <td align="center">
            <Quality value={vehicle.timeout} />
          </td>
          <td align="center">
              {vehicle.satellite}
          </td>
          <td align="center">
            <Quality value={vehicle.quality} />
          </td>
          <td align="center">
            {vehicle.datetime}
          </td>
          <td align="center">
              {vehicle.received}
          </td>
          <td align="left">
              {vehicle.address}
          </td>
        </tr>
      });
    }

    return content;
  }

  useInterval(() => {

    var type = "";

    if(clientId > 0 ){
      type = "CLIENT";
    }

    if(type !== ""){

      gridRepository.getGrid(clientId, (response) => {
	
        dispatch(gridAction(type, clientId, response.data));
      });
    }
   
  }, TIMER);
  
  return (

    <GridRastreamento>
      <div className="table-responsive">
        <table className="table table-striped grid-rastr">
          <thead>
              <tr>
                  <th><FaHouzz /> Todos ({list.length})</th>
                  <th>Tipo</th>
                  <th>Km/h</th>
                  <th>AL</th>
                  <th>B</th>
                  <th>IG</th>
                  <th>TO</th>
                  <th>S</th>
                  <th>Q</th>
                  <th>Dt Evento</th>
                  <th>Dt Sistema</th>
                  <th><FaMapMarkedAlt /> Prox.</th>                    
              </tr>
          </thead>
         <tbody>
          {htmlGrid()}
         </tbody>
        </table>
      </div>
    </GridRastreamento>
  );
}

export default Grid;