import React, { Component } from 'react'
import { connect } from 'react-redux'

import { validateFields } from './Validation';
import alertify from 'alertifyjs';

import Logo from '@/assets/img/wisetrack_logo_login.png';

import repository from './repository';

import userAction from '@/store/user/action';

const initialState = {
  user: {
    value: '',
    validateOnChange: false,
    error: ''
  },
  password: {
    value: '',
    validateOnChange: false,
    error: ''
  },

  submitCalled: false
};

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    
  }

  handleBlur(validationFunc, evt) {

    const field = evt.target.name;

    if (!this.state[field]['validateOnChange'] && !this.state.submitCalled) {

      this.setState(state => ({
        [field]: {
          ...state[field],
          validateOnChange: true,
          error: validationFunc(state[field].value)
        }
      }));
    }

    return;
  }

  handleChange(validationFunc, evt) {

    const field    = evt.target.name;
    const fieldVal = evt.target.value;
    
    this.setState(state => ({
      [field]: {
        ...state[field],
        value: fieldVal,
        error: state[field]['validateOnChange'] ? validationFunc(fieldVal) : ''
      }
    }));
  }

  handleSubmit(evt) {

    evt.preventDefault();

    const { user, password } = this.state;

    const userError     = validateFields.validateUser(user.value);
    const passwordError = validateFields.validatePassword(password.value);

    if (userError || passwordError) {

      alertify.error('Login e Senha são obrigatorios');
    }
    else{
      
      repository.executeLogin(user.value, password.value, (response) => {

        var status = response.status;

        if(status === 500){
          alertify.error('Login e/ou Senha inválidos.');
        }
        else{

          alertify.success('Login efetuado com sucesso');
   
          this.setState({ ...initialState });
         
          this.props.history.push('/app');
        }
      });      
    } 
  }

  render() {

    const { user, password } = this.state;

    return (
      <div>
        <React.Fragment>
          <div className="container bg-gray-mob mt-md-4 text-center">
            <img src={Logo} className="img-top-mob" alt="Logo" />
            <div className="box-login">
              <form onSubmit={evt => this.handleSubmit(evt)} className="form-container">
                
                <div className="mt-2">
                  <label className="pr-3">Usuário:</label>
                  <input type="text" name="user" value={user.value}
                   className="input-form form-control"
                    onChange={evt =>
                      this.handleChange(validateFields.validateUser, evt)
                    }
                    onBlur={evt =>
                      this.handleBlur(validateFields.validateUser, evt)
                    }
                  />
                </div>
                <div className="mt-2">
                  <label className="pr-3">Senha:</label>
                  <input
                    type="password"
                    name="password"
                    value={password.value}
                    className="input-form form-control"
                    onChange={evt =>
                      this.handleChange(validateFields.validatePassword, evt)
                    }
                    onBlur={evt =>
                      this.handleBlur(validateFields.validatePassword, evt)
                    }
                  />
                </div>
                <button
                  type="submit"
                  className="submit-login"
                  onMouseDown={() => this.setState({ submitCalled: true })}
                >
                  Entrar
              </button>
                <p className="mt-3 clearfix footer-box-login">
                  {/*
                  <small className="float-left font-12"><a href="#">Esqueceu sua password?</a></small>
                  */}
                </p>
              </form>
            </div>
            <p className="footer-login font-12">Copyright © 2021 - Todos os direitos reservados: WTS Serviços e Desenvolvimento em Informática Ltda.</p>
          </div>
        </React.Fragment>
      </div>
    )
  }
}

export default connect(
  null,
  { userAction }
)(Login)