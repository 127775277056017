import React from 'react';

import Without  from '@/assets/img/flag_gray.png';
import Yellow   from '@/assets/img/flag_yellow.png';
import Green    from '@/assets/img/flag_green.png';
import Red      from '@/assets/img/flag_red.png';

export default function AlertFlag({
  value
}) {

  let alert = Without

  if(value === 1){
    alert = Green
  }
  else if(value === 2){
    alert = Yellow
  }else if(value === 3){
    alert = Red
  }

  return (
    <>
      <img src={alert} alt="A" />
    </>
  );
}