import customApi from "@/componentes/api";

const URLS_COMBO = {
  DEV: "https://run.mocky.io/v3/14151716-30c1-46d5-85c0-d8a147f34fde",
  HOMOLOG: "http://localhost:8080/api/admin/mods/combo",
  PROD: "/api/admin/mods/combo"
}

function getCombo(callback) {

  customApi.get(URLS_COMBO, {}, callback);
}

export default {
  getCombo
};