import {React, useEffect} from "react";

export function ComboSleepTime({handle, name, value}) {

  useEffect(() => {
   
  }, []);

  return (
    <>
      <select onChange={handle} name={name} value={value}>
          <option value="0">-Selecione-</option>
          <option value="5">5 min</option>
          <option value="30">30 min</option>
          <option value="60">1 Hs</option>
          <option value="90">1 Hs 30min</option>
          <option value="120">2 hs</option>
      </select>
    </>
  )  
}

export default ComboSleepTime;