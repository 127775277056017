import customApi from "@/componentes/api";

const URLS_LOGOUT = {
  DEV: "https://run.mocky.io/v3/ea069009-7ca5-4beb-9275-afa402ea80df",
  HOMOLOG: "http://localhost:8080/logout",
  PROD: "/logout"
}

function logout(callback) {

  const params = {};

  customApi.post(URLS_LOGOUT, params, callback);
}

export default {
  logout
};