import React, {useState} from 'react'
import { Modal } from 'react-bootstrap'

import { FaFlag } from "react-icons/fa";
import { RiCellphoneFill } from "react-icons/ri";

function Line({
  lines
}){

  const [show, setShow] = useState(false)

  const handleModal = () => {
    setShow(!show)
  }

  const data = () => {

    var content = <tr>
        <td align="center" className="grid-empty" colspan="12">
          <FaFlag /> Nenhuma informação carregada.
        </td>
    </tr>

    if(lines){
      
      content = lines.map((info, index) => {

        return <tr align="center" key={index}>
          <td className="brd-right">{info.tituloLote}</td>
          <td className="brd-right">{info.tituloOperadora}</td>
          <td className="brd-right">{info.tecnologia}</td>
          <td className="brd-right">{info.titulo}</td>
        </tr>
      })
    }
     
    return content
  }

  return (
    <div>
      <a href="#" onClick={() => { handleModal() }}>
        <RiCellphoneFill size="30" />
      </a>
      <Modal show={show} onHide={() => handleModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Linhas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card-white mb-2">
            <table className="table table-striped mb-0">
              <thead>
                <tr align="center">
                  <th className="brd-right">Lote</th>
                  <th className="brd-right">Operadora</th>
                  <th className="brd-right">Tec.</th>
                  <th className="brd-right">Valor</th>
                </tr>
              </thead>
              <tbody>
                {data()}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
  
export default Line;