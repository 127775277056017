import customApi from "@/componentes/api";

const URLS_LIST = {
  DEV: "https://run.mocky.io/v3/f222cd44-9492-4aa3-9b9b-9d374d865e9a",
  HOMOLOG: "http://localhost:8080/api/operation/report/position",
  PROD: "/api/operation/report/position"
}

function getList(params, callback) {

  customApi.get(URLS_LIST, params, callback);
}

export default {
  getList
};