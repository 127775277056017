import {React, useEffect} from "react";

export function ComboIntervaloTimeout({handle, name, value}) {

  useEffect(() => {
   
  }, []);

  return (
    <>
      <select onChange={handle} name={name} value={value}>
          <option value="0">-Selecione-</option>
          <option value="1">1 min</option>
          <option value="3">3 min</option>
          <option value="5">5 min</option>
          <option value="10">10 min</option>
          <option value="15">15 min</option>
          <option value="30">30 min</option>
          <option value="60">1 Hs</option>
          <option value="360">6 Hs</option>
          <option value="720">12 Hs</option>
          <option value="1440">24 Hs</option>
      </select>
    </>
  )  
}
		

export default ComboIntervaloTimeout;