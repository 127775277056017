import styled from 'styled-components';

export const BoxVehicle = styled.div`

  width: 95%;
  height: 300px;
  background-color: #fff;
  overflow-x: scroll;
  margin: 10px;
  border-radius: 10px;
  border: 0px !important;

  .rstm-toggle-icon {
    display: inline-block; 
  }
  
  .rstm-toggle-icon-symbol {
    width: 1rem;
    height: 1rem;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }

.rstm-tree-item-group {
  list-style-type: none;
  padding-left: 0;
  text-align: left;
  width: 100%; 
}

.rstm-tree-item {
  padding: 5px;
  cursor: pointer;
  background: none;
  border-bottom: 1px solid #ccc;
  box-shadow: none;
  z-index: unset;
  position: relative; 
}

  .rstm-tree-item--active {
    font-weight: bold;
    border-bottom: none; 
  }

  .rstm-tree-item--focused {
    box-shadow: 0 0 5px 0 #222;
    z-index: 999; 
  }

.rstm-search {
  padding: 1rem 1.5rem;
  border: none;
  width: 100%; 
}
`

export const InputSearch = styled.input.attrs(props => ({
  type: "text",

}))`
  border-color: #779198;
  background-color: #fff !important;
`;
