import React, {useState} from 'react'
import { Modal } from 'react-bootstrap'

import { RiCellphoneFill, RiSendPlane2Fill } from "react-icons/ri";

function Command({}){

  const [show, setShow] = useState(false)

  const handleModal = () => {
    setShow(!show)
  }

  const data = () => {

  }

  return (
    <div>
      <a href="#" onClick={() => { handleModal() }}>
        <RiSendPlane2Fill size="30" />
      </a>
      <Modal show={show} onHide={() => handleModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Envio de Comandos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="form-row">
              <div className="form-group col">
                <label>Bloqueio:</label>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-9">
                <select className="form-control">
                  <option value="false">Desligado</option>
                  <option value="true">Ligado</option>
                </select>
              </div>
              <div className="form-group col-lg-3">
                <button type="button" className="btn btn-secondary">Enviar</button>
              </div>
            </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
  
export default Command;