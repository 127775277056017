import styled from 'styled-components';

export const InfoGrid = styled.section`
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
`
export const InfoGridItem = styled.div`
    margin-bottom: 10px;
    background-color: #fff;
`

export const InfoGridOption = styled.table`
    width: 97%;
    background-color: #fff;
    height: 30px;
    position:absolute;                  
    bottom:0;                          
`