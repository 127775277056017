import React, { useState } from 'react';

import useSound from 'use-sound';
import alertify from 'alertifyjs';

import AlertSound from '@/assets/audio/buzzer.mp3';

import AlertFlag from '@/componentes/alert_flag';
import useInterval from '@/componentes/useInterval';
import { ButtonPower } from './comp/ButtonPower';
import { ButtonVol } from './comp/ButtonVol';
import { format } from 'date-fns'
import { Modal, Button } from 'react-bootstrap';
import repository from './repository';

import './alerts.css'

const TIMER_SIGNAL = 1000
const TIMER_ALERT = 15000

function Alert() {

  const [total, setTotal] = useState({ "verde": 0, "amarelo": 0, "vermelho": 0 });
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);

  const [message, setMessage] = useState("");
  const [alertsToSave, setAlertsToSave] = useState([]);

  const [currentColor, setCurrentColor] = useState([]);
  const [sound, setSound] = useState(true);
  const [play] = useSound(AlertSound);

  const [currentType, setCurrentType] = useState(0);
  const [alertTypes, setAlertTypes] = useState([1, 2, 3]);

  const sendServer = (type) => {

    var arrTypes = [];

    if (type !== undefined) {

      arrTypes.push(type);
    }
    else if (currentType > 0) {

      arrTypes.push(currentType);
    }
    else {

      for (var index = 0; index < alertTypes.length; index++) {
        arrTypes.push(alertTypes[index]);
      }
    }

    repository.getAlerts(arrTypes, (response) => {

      var result = response.data;

      setTotal(result.listar.totalAlertas);

      var list = [];

      if (result.listar.alertas !== undefined && result.listar.alertas.length > 0) {
        list = result.listar.alertas;
      }

      setList(list);

      if (list.length > 0 && !show) {
        setShow(true);
      }

      if (sound && list.length > 0) {
        play();
      }
    });
  }

  const saveTreatment = () => {

    if (alertsToSave.length <= 0 || message === "") {
      alertify.error("Preencha os dados para o tratamento.");
    }
    else {

      var obj = {"alerts": alertsToSave, "message": message};

      repository.save(obj, (response) => {
    
        alertify.success("Gravação efetuada com sucesso.");

        closeModal();
      });

    }
  }

  const closeModal = () => {
    setShow(false);
    setCurrentType(0);
    setMessage("");
    setAlertsToSave([]);
  }

  const viewSpecificAlertType = (type) => {

    setCurrentType(type);

    sendServer(type);
  }

  const changeAlertType = (type) => {

    const array = alertTypes.slice();

    if (alertTypes.includes(type)) {
      array.splice(array.indexOf(type), 1)
    }
    else {
      array.push(type);
    }

    return array;
  }

  const containsAlertType = (type) => alertTypes.includes(type);

  const defineColorModalAlert = () => {

    var classColor;

    switch (currentType) {
      case 1:
        classColor = "modal-alert-green"
        break;
      case 2:
        classColor = "modal-alert-yellow"
        break;
      case 3:
        classColor = "modal-alert-red"
        break;
      default:
        classColor = "modal-alert"
    }

    return classColor;
  }

  useInterval(() => {

    var color;

    if (currentColor === "#fff") {

      if (total.vermelho > 0) {
        color = "#B22222";
      }
      else if (total.amarelo > 0) {
        color = "#FFD700";
      }
      else if (total.verde > 0) {
        color = "#00A65A";
      }
      else {
        color = "#fff";
      }
    }
    else {
      color = "#fff";
    }

    setCurrentColor(color)

  }, TIMER_SIGNAL);

  useInterval(() => { sendServer() }, TIMER_ALERT);

  const add = (e) => {

    var value = parseInt(e.target.value);

    if (e.target.checked) {
      alertsToSave.push(value)
    }
    else {

      const index = alertsToSave.indexOf(value);

      if (index > -1) {
        alertsToSave.splice(index, 1);
      }
    }
  };

  const addAlertsAll = (e) => {

    var ele = document.getElementsByName('alerts');

    for (var i = 0; i < ele.length; i++) {

      if (ele[i].type === 'checkbox') {

        ele[i].checked = e.target.checked;

        if (e.target.checked) {
          alertsToSave.push(ele[i].value)
        }
      }
    }

    if (!e.target.checked) {
      setAlertsToSave([])
    }
  }

  return (

    <div className="card-white mt-2" style={{ background: currentColor }} >
      <div className="content-alerts">
        <ul className="menu-alert">
          <li className="modal-alert-green">
            <a href="#" onClick={() => viewSpecificAlertType(1)}>
              {total.verde}
            </a>
            <hr />
            <ButtonPower actual={containsAlertType(1)} toggleSelected={() => {
              setAlertTypes(changeAlertType(1));
            }} />
          </li>

          <li className="modal-alert-yellow">
            <a href="#" onClick={() => viewSpecificAlertType(2)}>
              {total.amarelo}
            </a>
            <hr />
            <ButtonPower actual={containsAlertType(2)} toggleSelected={() => {
              setAlertTypes(changeAlertType(2));
            }} />
          </li>

          <li className="modal-alert-red">
            <a href="#" onClick={() => viewSpecificAlertType(3)}>
              {total.vermelho}
            </a>
            <hr />
            <ButtonPower actual={containsAlertType(3)} toggleSelected={() => {
              setAlertTypes(changeAlertType(3));
            }} />
          </li>
        </ul>
      </div>
      <div className="content-alerts-sound">
        <div className="icon-sound">
          <ButtonVol actual={sound} toggleSelected={() => {
            setSound(!sound);
          }} />
        </div>
      </div>

      <Modal size="lg" show={show} onHide={closeModal}>
        <Modal.Header closeButton className={defineColorModalAlert()}>
          <Modal.Title>Alertas ({list.length})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card-white mb-2">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="brd-right">
                      <input type="checkbox" id="alertsAll" onClick={addAlertsAll} />
                    </th>
                    <th className="brd-right">Veículo</th>
                    <th className="brd-right">AL</th>
                    <th className="brd-right">Evento</th>
                    <th className="brd-right">Data</th>
                    <th>Descrição</th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((alert, index) =>
                    <tr key={index}>
                      <td className="brd-right">
                        <input type="checkbox"
                          name="alerts"
                          id={"alerts" + index}
                          value={alert.idAlerta}
                          onChange={(e) => add(e)}
                        />
                      </td>
                      <td className="brd-right">{alert.tituloVeiculo}</td>
                      <td className="brd-right">
                        <AlertFlag value={alert.alerta} />
                      </td>
                      <td className="brd-right">{alert.tituloEs}</td>
                      <td className="brd-right">
                        {format(alert.data, 'dd/MM/yyyy HH:mm:ss')}
                      </td>
                      <td>{alert.titulo}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <label className="mb-1">Digite as informações:</label>
          <textarea className="form-control" rows={3} onChange={(e) => setMessage(e.target.value)} />
        </Modal.Body>
        <Modal.Footer>
          <div className="form-row">
            <div className="form-group">
              <Button variant="primary" onClick={() => saveTreatment()}>Realizar tratamento</Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Alert;