import customApi from "@/componentes/api";

const URLS_SAVE = {
  DEV: "https://run.mocky.io/v3/bd94ff8e-7d58-4020-945e-654c6329edb2",
  HOMOLOG: "http://localhost:8080/api/admin/vehicles",
  PROD: "/api/admin/vehicles"
}

const URLS_GET = {
  DEV: "https://run.mocky.io/v3/f7a565fe-74e7-49db-b0e1-685caf993a5c",
  HOMOLOG: "http://localhost:8080/api/admin/vehicles/:id",
  PROD: "/api/admin/vehicles/:id"
}

const URLS_LIST = {
  DEV: "https://run.mocky.io/v3/d54e9496-cc8c-4caa-bb92-74a04a76bca1",
  HOMOLOG: "http://localhost:8080/api/admin/vehicles",
  PROD: "/api/admin/vehicles"
}

const URLS_COMBO_SELECTED = {
  DEV: "https://run.mocky.io/v3/14151716-30c1-46d5-85c0-d8a147f34fde",
  HOMOLOG: "http://localhost:8080/api/admin/vehicles/combo/selected",
  PROD: "/api/admin/vehicles/combo/selected"
}

const URLS_COMBO_PROFILE = {
  DEV: "https://run.mocky.io/v3/14151716-30c1-46d5-85c0-d8a147f34fde",
  HOMOLOG: "http://localhost:8080/api/admin/vehicles/combo/profile",
  PROD: "/api/admin/vehicles/combo/profile"
}

function getList(params, callback) {

  customApi.get(URLS_LIST, params, callback);
}

function getView(id, callback) {

  var params = {":id": id};

  customApi.getPath(URLS_GET, params, callback);
}

function save(obj, callback) {

  customApi.post(URLS_SAVE, obj, callback);
}

function getComboSelected(callback) {

  customApi.get(URLS_COMBO_SELECTED, {}, callback);
}

function getComboProfile(callback) {

  customApi.get(URLS_COMBO_PROFILE, {}, callback);
}

export default {
  getList, getView, save, getComboSelected, getComboProfile
};