import customApi from "@/componentes/api";

const URLS = {
  DEV: "https://run.mocky.io/v3/6d73cb58-6321-4af1-a7ce-38ac1daa7d67",
  HOMOLOG: "http://localhost:8080/api/operation/info/:id",
  PROD: "/api/operation/info/:id"
}

function getInfo(vehicleId, callback) {

  var params = {":id": vehicleId};

  customApi.getPath(URLS, params, callback);
}

export default {
  getInfo
};