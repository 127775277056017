import customApi from "@/componentes/api";
import qs from "querystring";

const URLS = {
  DEV: "https://run.mocky.io/v3/91c6e7e8-ae80-42e3-aced-a7c86cd4cd15",
  HOMOLOG: "http://localhost:8080/api/operation/tree",
  PROD: "/api/operation/tree",
}

function getAll(vehicleType, param, callback) {

  const params = {};

  if(param !== undefined && param !== ""){
    params.text = param;
  }

  if(vehicleType !== undefined && vehicleType !== ""){
    params.vehicleType = vehicleType;
  }

  customApi.post(URLS, params, callback);
}

export default {
  getAll
};