import React, { useEffect, useState } from "react";

import { Link } from 'react-router-dom'
import alertify from 'alertifyjs';

import repository from './repository';
import { Form, IconList } from '@/componentes/form/styles';
import CheckAll from '@/componentes/checkall';
import Template from '@/modules/admin/template';
import useForm from '@/componentes/form/useForm';

import Quality from '@/componentes/quality';

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import repositoryVehicle from '../vehicle/repository';
import repositoryUser from '../user/repository';

export function ProfileForm(props) {

    const url = "/admin/profiles";

    const dataInitial = {
        title: "",
        description: "",
        status: true,
        id: 0,
        vehicles: [],
        users: []
    };

    const { values, setValues, handleChange, clearForm } = useForm(dataInitial);

    const [comboVehicles, setComboVehicles] = useState([]);
    const [comboUsers, setComboUsers] = useState([]);
    
    useEffect(() => {

        if (props.match.params.id) {

            repository.getView(props.match.params.id, (response) => {
                setValues(response.data);
                setComboVehicles(response.data.comboVehicles);
                setComboUsers(response.data.comboUsers);
            });
        }
        else{

            repositoryVehicle.getComboSelected((response) => {
                setComboVehicles(response.data);
            });

            repositoryUser.getComboUserProfile((response) => {
                setComboUsers(response.data);
            });
        }
       
    }, [props.match.params.id, setValues]);

    const handleSubmit = (e) => {

        e.preventDefault();

        repository.save(values, (response) => {

            alertify.success(response.data.message);

            clearForm();

            props.history.push(url);
        });
    }

    const add = (e, arr) => {

        var value = parseInt(e.target.value);

        if (e.target.checked) {
            arr.push(value)
        }
        else {
            
            const index = arr.indexOf(value);

            if (index > -1) {
                arr.splice(index, 1);
            }
        }
    };

    const addVehicleAll = (checkedItems) => {

        if (checkedItems.length > 0) {

            checkedItems.forEach(item => values.vehicles.push(item));
        }
        else {
            values.vehicles = [];
        }
    }

    return (
        <>
            <Template title="Perfil">

                <Form onSubmit={handleSubmit}>
                    <main className="container">

                        <div className="row">
                            <div className="col-sm-12">
                                <Link to={url}>
                                    <IconList size={30} />
                                </Link>
                            </div>
                        </div>

                        <Tabs defaultActiveKey="profile" id="controlled-tab">
                            <Tab eventKey="profile" title="Dados Básicos">

                                <div className="row">
                                    <div className="col-sm-6">
                                        <label>Nome</label>
                                        <input type="text"
                                            name="title"
                                            id="title"
                                            maxLength="50"
                                            value={values.title}
                                            onChange={handleChange} />
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="status">Status</label>
                                        <select value={values.status} onChange={handleChange} name="status" id="status" >
                                            <option value={true}>Ativo</option>
                                            <option value={false}>Desativado</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12">
                                        <label>Descrição</label>
                                        <input type="text"
                                            name="description"
                                            id="description"
                                            maxLength="255"
                                            value={values.description}
                                            onChange={handleChange} />
                                    </div>
                                </div>
                            </Tab>

                            <Tab eventKey="vehicles" title="Veículos">
                                <CheckAll arrCheckName="vehicleId" handleAll={addVehicleAll} />

                                <table className="table table-striped table_grid">
                                    <thead>
                                    <tr>
                                        <th>Cliente</th>
                                        <th>Veículo</th>
                                        <th>Status</th>
                                        <th className="option"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {comboVehicles.map((obj, index) => {
                                        return <tr key={index}>
                                              <td>{obj.clientTitle}</td>
                                              <td>{obj.title}</td>
                                              <td><Quality value={obj.status} /></td>
                                              <td className="option">
                                                  <input type="checkbox" 
                                                          name="vehicleId" 
                                                          id={"vehicleId"+index} 
                                                          value={obj.id}
                                                          onChange={(e) => add(e, values.vehicles)}
                                                          defaultChecked={obj.checked}
                                                          alt={index}
                                                          />
                                              </td>
                                          </tr>
                                    })}
                                    </tbody>
                                </table>
                            </Tab>

                            <Tab eventKey="users" title="Usuários">
                                
                                <table className="table table-striped table_grid">
                                    <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Login</th>
                                        <th>Status</th>
                                        <th className="option"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {comboUsers.map((obj, index) => {
                                        return <tr key={index}>
                                              <td>{obj.name}</td>
                                              <td>{obj.login}</td>
                                              <td><Quality value={obj.status} /></td>
                                              <td className="option">
                                                  <input type="checkbox" 
                                                          name="userId" 
                                                          id={"userId"+index} 
                                                          value={obj.id}
                                                          onChange={(e) => add(e, values.users)}
                                                          defaultChecked={obj.checked}
                                                          alt={index}
                                                          />
                                              </td>
                                          </tr>
                                    })}
                                    </tbody>
                                </table>
                            </Tab>

                        </Tabs>

                        <div className="row">
                            <button>Salvar</button>
                        </div>
                    </main>
                </Form>
            </Template>
        </>
    )
}

export default ProfileForm;