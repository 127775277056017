import React from "react";

export function ComboStatus({handleList}) {

  return (
    <>
      <select onChange={handleList} name="status">
          <option value={true}>Ativo</option>
          <option value={false}>Desativado</option>
      </select>
    </>
  )  
}

export default ComboStatus;