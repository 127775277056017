import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';
import alertify from 'alertifyjs';

import { Form } from '@/componentes/form/styles';
import useForm  from '@/componentes/form/useForm';
import repository from './repository';

import { RiLockPasswordFill } from "react-icons/ri";
import Tooltip from 'rc-tooltip';

function Pass() {

    const [show, setShow] = useState(false);

    const data ={
        pass: "",
        newPass:"",
        confNewPass:""
    }

    const {values, setValues, handleChange, clearForm } = useForm(data);

    const save = (e) => {

        e.preventDefault();

        repository.save(values, (response) => {

            alertify.success(response.data.message);
    
            clearForm();
    
            closeModal();

           
        });
    }

    const openModal = () => {
        setShow(true);
    }

    const closeModal = () => {
        setShow(false);

        setValues(data);
    }

    return (

        <div>
            <Tooltip placement="bottom" overlay="Alterar Senha ?">
                <a href="#" className="item-menu" onClick={openModal}>
                    <div>
                        <RiLockPasswordFill size={30} className="icon-operacao" />
                    </div>
                </a>
            </Tooltip>
                       
            <Modal size="lg" show={show} onHide={closeModal} >
                <Modal.Header closeButton className="modal-alert-red">
                    <Modal.Title>Alterar Senha</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                <Form onSubmit={save}>
                    <main className="container">

                        <div className="row">
                            <div className="col-sm-12">
                                <label htmlFor="group">Senha Atual *</label>
                                <input type="password"
                                    name="pass"
                                    id="pass"
                                    maxLength="20"
                                    value={values.pass}
                                    onChange={handleChange}
                                     />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <label>Nova Senha *</label>
                                <input type="password"
                                    name="newPass"
                                    id="newPass"
                                    maxLength="20"
                                    value={values.newPass}
                                    onChange={handleChange}
                                     />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <label>Confirmar Nova Senha *</label>
                                <input type="password"
                                    name="confNewPass"
                                    id="confNewPass"
                                    maxLength="20"
                                    value={values.confNewPass}
                                    onChange={handleChange}
                                     />
                            </div>
                        </div>
                        <div className="row">
                            <button>Salvar</button>
                        </div>
                    </main>
                </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Pass;