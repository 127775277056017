import React, { useState, useEffect } from "react";
import { Link }   from 'react-router-dom'

import repository   from './repository';
import Template     from '@/modules/admin/template';

export function LineLot() {

  const [list, setList]       = useState([]);
  const [filters, setFilters] = useState({});
      
  useEffect(() => {
    handleList();
  }, []);

  const handleList = (e) => {

    new Promise((resolve, reject) => {
      
      if(e !== undefined){

        var params = {...filters, 
                     [e.target.getAttribute('name')]:  e.target.value};

        setFilters(params);

        resolve(params);
      }
      else{
        resolve({...filters});
      }
      
    }).then((result) => {

      repository.getList(result, (response) => {
        setList(response.data);
      });
    });
  }

  return (
    <>
      <Template title="Lote de Linhas">
        <div class="table-responsive">
          <table class="table table-striped table_grid">
            <thead>
              <tr>
                <th>Titulo</th>
                <th>Contrato</th>
                <th>Operadora</th>
                <th className="option">
                  <Link to="/admin/line/lots/form">
                    <button>Novo</button>
                  </Link>
                </th>
              </tr>
            </thead>
            <tbody>
              {list &&
              list.map((obj, index) => (
                <tr key={index}>
                  <td>{obj.title}</td>
                  <td>{obj.contract}</td>
                  <td>{obj.oper}</td>
                  <td className="option">
                    <Link to={`/admin/line/lots/form/${obj.id}`}>
                      <button>Editar</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Template>
    </>
  )
}

export default LineLot;