import React from 'react';

import True  from '@/assets/img/true.png';
import False from '@/assets/img/false.png';

export default function Quality({
  value
}) {

  return (
    <>
      <img src={value ? True : False} alt="Qualidade de posição" />
    </>
  );
}