import React, { useState } from "react";
import Template from '@/modules/report/template';
import ReactPaginate from 'react-paginate'

import alertify from 'alertifyjs';

import repository from './repository';
import ComboVehicleProfile from "@/modules/admin/combos/vehicle_profile";

import { SearchButton } from '@/modules/report/comp/button';

export function ReportPosition() {

  const dataInitial = {
    period: "hour",
    vehicle: "",
    page: 0
  };

  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filters, setFilters] = useState(dataInitial);
  const [msg, setMsg] = useState("");

  function handleChange(e) {

    setFilters({
      ...filters,
      [e.target.getAttribute('name')]: e.target.value,
    });
  }

  const handlePageClick = (data) => {

    var offset = data ? Math.ceil(data.selected + 1) : 1;

    sendRequest(offset)
  }

  const handleClick = (e) => {

    if (filters.vehicle <= 0) {
      alertify.error("Por favor, selecione um Veículo.")
      return;
    }

    new Promise((resolve, reject) => {

      if (e !== undefined) {

        var params = {
          ...filters,
          [e.target.getAttribute('name')]: e.target.value
        };

        setFilters(params);

        resolve(params);
      }
      else {
        resolve({ ...filters });
      }

    }).then(() => {

      sendRequest(0);

    });
  };

  const sendRequest = (page) => {

    //Pagination
    setFilters({
      ...filters,
      "page": page
    });


    var params = {
      "vehicle": filters.vehicle,
      "period": filters.period,
      "page": page
    }

    repository.getList(params, (response) => {

      if (response.data.pagination.totalElements !== undefined && response.data.pagination.totalElements > 0) {
        setMsg(`Total de ${response.data.pagination.totalElements} registros encontrados.`);
      }
      else {
        setMsg("Nenhum registro encontrado.");
      }

      setList(response.data.list);
      setPagination(response.data.pagination);
    });
  }

  return (
    <>
      <Template title="Relatório de Posições">
        <div className="table-responsive">
          <div className="table_grid_filter">
            <div className="row">
              <div className="col-sm-4">Período</div>
              <div className="col-sm-4">Veículo</div>
              <div className="col-sm-4"></div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <select name="period" onChange={handleChange}>
                  <option value="hour">Ultima Hora</option>
                  <option value="day">Ultima Dia</option>
                  <option value="week">Ultima Semana</option>
                  <option value="month">Ultima Mês</option>
                  <option value="three_month">Ultimos 3 Mês</option>
                </select>
              </div>
              <div className="col-sm-4">
                <ComboVehicleProfile handle={handleChange} name="vehicle" />
              </div>
              <div className="col-sm-4">
                <SearchButton onClick={handleClick} />
              </div>
            </div>
          </div>
          <table className="table table-striped table_grid">
            <thead>
              <tr>
                <th>ID</th>
                <th>Veículo</th>
                <th>Equip.</th>
                <th>Data Evento</th>
                <th>Data Sistema</th>
                <th>Latitude</th>
                <th>Longitude</th>
                <th>Localização</th>
              </tr>
            </thead>
            <tbody>
              {list &&
                list.map((obj, index) => (
                  <tr key={index}>
                    <td>{obj.id}</td>
                    <td>{obj.vehicle}</td>
                    <td>{obj.userId}</td>
                    <td>{obj.datetime}</td>
                    <td>{obj.received}</td>
                    <td>{obj.latitude}</td>
                    <td>{obj.longitude}</td>
                    <td>{obj.address}</td>
                  </tr>
                ))}
            </tbody>
            <tfoot className="container_pagination">
              <tr>
                <td colspan="8">
                  <p>{msg}</p>
                </td>
              </tr>
              <tr>
                <td colspan="8">
                  <div style={pagination.totalElements !== undefined && pagination.totalElements > 0 ? { display: 'block' } : { display: 'none' }}>
                    <ReactPaginate
                      previousLabel={'Anterior'}
                      nextLabel={'Próxima'}
                      breakLabel={'...'}
                      pageCount={pagination.totalPages}
                      marginPagesDisplayed={5}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      containerClassName={'pagination'}
                      activeClassName={'active'}
                    />

                  </div>

                </td>
              </tr>

            </tfoot>
          </table>
        </div>
      </Template>
    </>
  )
}

export default ReportPosition;