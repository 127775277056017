import customApi from "@/componentes/api";

const URLS_SAVE = {
  DEV: "https://run.mocky.io/v3/d54e9496-cc8c-4caa-bb92-74a04a76bca1",
  HOMOLOG: "http://localhost:8080/api/admin/profiles",
  PROD: "/api/admin/profiles"
}

const URLS_GET = {
  DEV: "https://run.mocky.io/v3/f7a565fe-74e7-49db-b0e1-685caf993a5c",
  HOMOLOG: "http://localhost:8080/api/admin/profiles/:id",
  PROD: "/api/admin/profiles/:id"
}

const URLS_LIST = {
  DEV: "https://run.mocky.io/v3/d54e9496-cc8c-4caa-bb92-74a04a76bca1",
  HOMOLOG: "http://localhost:8080/api/admin/profiles",
  PROD: "/api/admin/profiles"
}

function getList(params, callback) {

  customApi.get(URLS_LIST, params, callback);
}

function getView(id, callback) {

  var params = {":id": id};

  customApi.getPath(URLS_GET, params, callback);
}

function save(obj, callback) {

  customApi.post(URLS_SAVE, obj, callback);
}

export default {
  getList, getView, save
};