  const INITIAL_STATE = {
    clientId: 0,
    list: [],
    hasData: false
  };

  export default function (state = INITIAL_STATE, action) {
      
    switch (action.type) {
      case "CLIENT":
        return {list: action.payload.list, clientId: action.payload.clientId, hasData: true};
      case "EMPTY":
          return INITIAL_STATE;
      default:
        return state
    }
  }