import React from 'react';

import Vehicles         from '@/modules/operacao/main/arvore'
import Grid             from '@/modules/operacao/main/grid';
import Info             from '@/modules/operacao/main/info';
import Mapa             from '@/modules/operacao/main/map';

const main = () => {

  return (
    <section className="pb-3">
      <div className="container-fluid">
        <div className="form-row">
          <Vehicles />
          <Info />

          <div className="form-group col-lg-3 col-xl-4 px-lg-2 order-2 order-lg-3">
              <div className="card h100">
                  <div id="map" style={{height: '495px', width: '100%'}}>
                  <Mapa />
                  </div>
              </div>
          </div>
          
        </div>
        <Grid />
      </div>
    </section>
  )
}

export default main;