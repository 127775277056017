import React  from 'react';
import Header from '@/modules/operacao/header';
import Main   from '@/modules/operacao/main/Main';

import {Provider} from 'react-redux'

import store  from '@/store';

function Operacao() {
  
  return (

    <Provider store={store}>
      <div>
        <Header/>
        <Main/>
      </div>
    </Provider>  
  );
}

export default Operacao;