import customApi from "@/componentes/api";
import qs from "querystring";

const URLS = {
  DEV: "https://run.mocky.io/v3/fe5cc9ce-cadf-4305-9143-423ada790912",
  HOMOLOG: "http://localhost:8080/login",
  PROD:"/login"
}

function executeLogin(login, senha, callback) {

  const params = {};

  params.username = login;
  params.password = senha;
  
  customApi.post(URLS, qs.stringify(params), callback);
}

export default {
  executeLogin
};