import React, { useState, useEffect } from "react";

import repository from './repository';
import Template from '@/modules/admin/template';
import { Link } from 'react-router-dom'

import './active.css';

export function DeviceActive() {

  const dataInitial = {
    userId: "",
    number: "",
    hasLine: true,
    hasVehicle: true
  };

  const [list, setList] = useState([]);
  const [filters, setFilters] = useState(dataInitial);

  useEffect(() => {
    handleList();
  }, []);

  const handleList = (e) => {

    new Promise((resolve, reject) => {

      if (e !== undefined) {

        var params = {
          ...filters,
          [e.target.getAttribute('name')]: e.target.value
        };

        setFilters(params);

        resolve(params);
      }
      else {
        resolve({ ...filters });
      }

    }).then((result) => {

      repository.getList(result, (response) => {
        setList(response.data);
      });
    });
  };

  return (
    <>
      <Template title="Ativação de Equipamentos">
        <div className="table-responsive">
          <div className="table_grid_filter">
            <div className="row">
              <div className="col-sm-3">Equipamento</div>
              <div className="col-sm-3">Linha</div>
              <div className="col-sm-3">Possui Linha ?</div>
              <div className="col-sm-3">Veiculo Associado ?</div>
            </div>
            <div className="row">
              <div className="col-sm-3">
                <input type="text" onChange={handleList} name="userId" id="userId" maxLength="20" />
              </div>
              <div className="col-sm-3">
                <input type="text" onChange={handleList} name="number" id="number" maxLength="15" />
              </div>
              <div className="col-sm-3">
                <select onChange={handleList} name="hasLine" value={filters.hasLine}>
                  <option value="">Todos</option>
                  <option value={true}>Sim</option>
                  <option value={false}>Não</option>
                </select>
              </div>
              <div className="col-sm-3">
                  <select onChange={handleList} name="hasVehicle" value={filters.hasVehicle}>
                    <option value="">Todos</option>
                    <option value={true}>Sim</option>
                    <option value={false}>Não</option>
                  </select>
              </div>
            </div>
          </div>
          <table className="table table-striped table_grid">
            <thead>
              <tr>
                <th>Equipamento</th>
                <th>Número de Serie</th>
                <th>Linha</th>
                <th>Veículo</th>
                <th>Cliente</th>
                <th className="option"></th>
              </tr>
            </thead>
            <tbody>
              {list &&
                list.map((obj, index) => (
                  <tr key={index}>
                    <td>{obj.title}</td>
                    <td>{obj.serialNumber}</td>
                    <td>{obj.lineNumber}</td>
                    <td>{obj.vehicleTitle}</td>
                    <td>{obj.clientTitle}</td>
                    <td className="option">
                      <Link to={`/admin/device/actives/form/${obj.id}`}>
                        <button>Editar</button>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Template>
    </>
  )
}

export default DeviceActive;